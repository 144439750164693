import { memo } from "react"
import { sizer } from "@ninjaone/utils"
import { Text, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { Box, Flex, StyledSpan } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const MobileDeviceTypePicker = memo(({ disabled, onChange, selected, options, required }) => {
  const tooltipMessage = localized(
    `End user device can be used on both personally owned and company owned devices. Allowing personal usage will create a work profile on the device to separate work and personal functionality.\n
    Personally owned (BYOD) devices: End user device - allow personal usage
    Company owned personally enabled (COPE): End user device - allow personal usage
    Company owned user assigned without separation: End user device - no personal usage allowed\n
    Dedicated devices are not meant as end user assigned devices. Device usage of this type could be; kiosk station, point of sale, scanner, or other.\n
    Dedicated device - No specific user.`,
  )
  return (
    <Box marginTop={sizer(3)} data-testid="mobile-device-box">
      <StyledLabel htmlFor="mobile-device-type-selector">
        <Flex>
          <StyledSpan marginRight={1}>
            {localized("Usage type")}{" "}
            {required && (
              <>
                <span aria-hidden="true">&nbsp;*</span>
                <Text token={localizationKey("Required")} visuallyHidden />
              </>
            )}
          </StyledSpan>
          <Tooltip label={tooltipMessage}>
            <RegularInfoCircleIcon size="sm" />
          </Tooltip>
        </Flex>
      </StyledLabel>
      <SearchableDropDown
        {...{
          id: "mobile-device-type-selector",
          disabled,
          onSelect: onChange,
          options,
          value: selected,
          searchPlaceholderToken: localizationKey("Select usage type"),
          minHeight: "42px",
          width: "100%",
          rowHeight: 40,
          keepInView: false,
        }}
      />
    </Box>
  )
})

export default MobileDeviceTypePicker
