import { AppleIcon, LinuxIcon, WindowsIcon } from "@ninjaone/icons"
import { localizationKey } from "js/includes/common/utils"

export const computerOptions = [
  {
    labelToken: localizationKey("Windows"),
    value: "WINDOWS_MSI",
    icon: <WindowsIcon fontSize="20px" />,
  },
  {
    labelToken: localizationKey("Mac"),
    value: "MAC",
    icon: <AppleIcon fontSize="20px" />,
  },
  {
    labelToken: localizationKey("Linux"),
    value: "LINUX",
    icon: <LinuxIcon fontSize="20px" />,
  },
]

export const linuxPackageTypeOptions = [
  { value: "DEB", label: "Debian" },
  { value: "RPM", label: "RPM" },
]

export const defaultLinuxPackageType = linuxPackageTypeOptions[0]

export const defaultLinuxArchitectureOption = { label: "x86_64", value: "" }

export const getLinuxArchitectureOptions = () => [
  defaultLinuxArchitectureOption,
  { label: "Raspberry Pi (64-bit)", value: "ARM64" },
  { label: "Raspberry Pi (32-bit)", value: "ARMV7A" },
]

export const macPackageTypeOptions = [
  { value: "MAC_DMG", label: "DMG" },
  { value: "MAC_PKG", label: "PKG" },
]

export const defaultMacPackageType = macPackageTypeOptions[0]
