import { useCallback, useEffect } from "react"
import PropTypes from "prop-types"

import { DataTable, Modal } from "@ninjaone/components"
import { isRequiredIf, useMountedState } from "@ninjaone/utils"

import { Box } from "js/includes/components/Styled"
import {
  fetchJson,
  localizationKey,
  localized,
  localizedWith,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { fetchAssignedOrganizations } from "js/includes/common/client"

function ViewOrganizationsModal({ unmount, appData }) {
  const [organizations, setOrganizations] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)
  const [error, setError] = useMountedState(null)

  const titleGroup = {
    titleText: localized("Organizations"),
    descriptionText: localizedWith(
      "The following organizations are assigned a content token that includes <%appName> <%>.",
      {
        appName: () => <strong>{appData.name}</strong>,
      },
    ),
  }

  const columns = [
    {
      id: "name",
      Header: localized("Organization"),
      accessor: "name",
    },
  ]

  const loadAssignedOrganizations = useCallback(async () => {
    setLoading(true)
    try {
      const { applicationId, country } = appData

      const [organizations, data] = await Promise.all([
        fetchJson("/client/list"),
        ...(appData.organizations ? [] : [fetchAssignedOrganizations([{ applicationId, country }])]),
      ])

      const appOrganizations = appData.organizations ? appData.organizations : data?.[0]?.organizations || []
      const mappedOrganizations = organizations.reduce((acc, { id, name }) => {
        if (appOrganizations.includes(id)) {
          acc.push({ id, name })
        }
        return acc
      }, [])

      setOrganizations(mappedOrganizations)
    } catch (error) {
      const errorToken = localizationKey("Error loading organizations. Please try again later.")
      reportErrorAndShowMessage(error, errorToken)
      setError(localized(errorToken))
    } finally {
      setLoading(false)
    }
  }, [appData, setError, setLoading, setOrganizations])

  useEffect(() => {
    loadAssignedOrganizations()
  }, [loadAssignedOrganizations])

  return (
    <Modal {...{ titleGroup, unmount }} size="md">
      <Box height="61vh">
        <DataTable
          {...{
            tableId: "app-organizations-table",
            columns,
            rows: organizations,
            pageSizeLimit: 10,
            hideResultsCount: true,
            hideSettingsButton: true,
            searchPlaceholderText: localized("Enter organization"),
            noRowsToken: localizationKey("No assigned organizations"),
            loading,
            error,
          }}
        />
      </Box>
    </Modal>
  )
}

export default ViewOrganizationsModal

ViewOrganizationsModal.propTypes = {
  unmount: PropTypes.func,
  /**
   * Should contain either an `orgnizations` that includes the list of assgined organizations, or provide an `applicationId` and `country` so they can be fetched instead.
   */
  appData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    applicationId: PropTypes.number,
    country: PropTypes.string,
    organizations: isRequiredIf(
      PropTypes.arrayOf(PropTypes.number),
      ({ applicationId, country }) => !applicationId || !country,
      "`organizations` is required if `applicationId` and `country` are not provided",
    ),
  }).isRequired,
}
