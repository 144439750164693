import { useCallback, useEffect, useRef } from "react"
import { useMountedState } from "js/includes/common/hooks"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"

type Options<T> = {
  initialValue: T
  reportOnError?: boolean
  withAbort?: boolean
}

export const useFetch = <T>(
  endpoint: string | null,
  { initialValue, reportOnError = true, withAbort = true }: Options<T> = {} as Options<T>,
) => {
  const [loading, setLoading] = useMountedState(false)
  const [value, setValue] = useMountedState<T>(initialValue)

  const abortControllerRef = useRef<null | AbortController>(null)

  const fetchData = useCallback(async () => {
    if (!endpoint) return

    if (withAbort) {
      // if abortControllerRef.current is specified - it means we have previous awaiting fetchJson which should be aborted
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      abortControllerRef.current = new AbortController()
    }

    setLoading(true)
    try {
      const data: T = await fetchJson(endpoint, {
        options: withAbort ? { signal: abortControllerRef.current?.signal } : {},
      })
      setValue(data)
      if (withAbort) {
        abortControllerRef.current = null //on the next fetchData iteration we don't need to abort because it was successfull
      }
    } catch (error) {
      //when abort, by default "AboutError" is thrown, we don't report it
      const isNotAbortError = !withAbort || error.name !== "AbortError"
      if (reportOnError && isNotAbortError) {
        reportErrorAndShowMessage(error)
      }
    } finally {
      setLoading(false)
    }
  }, [setValue, setLoading, endpoint, reportOnError, withAbort])

  useEffect(() => {
    ;(async () => await fetchData())()
    if (withAbort) {
      return () => abortControllerRef.current?.abort() //aborting if component is unmounted but we still have active fetchJson
    }
  }, [fetchData, withAbort])

  return [value, loading, fetchData] as [T, boolean, () => Promise<void>]
}
