import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import Body from "../Typography/Body"
import ReadOnlyContainer from "../ReadOnlyContainer"

const StyledItem = styled.li`
  overflow: visible;
`

const ConfirmationModalList = ({ items = [], maxItemsToDisplay = 9 }) => {
  if (!items.length) {
    return null
  }

  return (
    <ReadOnlyContainer as="ul">
      {items.slice(0, maxItemsToDisplay).map(item => {
        return (
          <StyledItem key={item}>
            <Body>{item}</Body>
          </StyledItem>
        )
      })}

      {items.length > maxItemsToDisplay && (
        <StyledItem>
          <Body>{localized("+{{count}} more", { count: items.length - maxItemsToDisplay })}</Body>
        </StyledItem>
      )}
    </ReadOnlyContainer>
  )
}

ConfirmationModalList.propTypes = {
  /**
   * The text items to display in the component.
   */
  items: PropTypes.arrayOf(PropTypes.string),
  /**
   * The maximum number of items to display in the component.
   */
  maxItemsToDisplay: PropTypes.number,
}

export default ConfirmationModalList
