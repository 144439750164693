const initSync = () => ({
  type: "INIT_PRODUCT_AGREEMENT_SYNC",
})

const setSync = payload => ({
  type: "SET_PRODUCT_AGREEMENT_SYNC",
  payload,
})

export function setProductAgreementSync(sync) {
  return dispatch => {
    sync ? dispatch(setSync(sync)) : dispatch(initSync())
  }
}

export function setEnabled(enabled) {
  return dispatch => {
    dispatch({ type: "SET_PRODUCT_AGREEMENT_SYNC_ENABLED", enabled })
  }
}

export function setSchedule(schedule) {
  return dispatch => {
    dispatch({ type: "SET_PRODUCT_AGREEMENT_SYNC_SCHEDULE", schedule })
  }
}
