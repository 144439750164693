import { useMemo } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { ItemText, Portal, Root, Viewport, Separator } from "@radix-ui/react-select"
import { v4 as uuidv4 } from "uuid"
import { localized, getTimeStringForTimezone } from "js/includes/common/utils"
import { SelectIconComponent } from "./Components/SelectIcon"
import { SelectLabelComponent } from "./Components/SelectLabel"
import { SelectValueComponent } from "./Components/SelectValue"

import { StyledBaseContent, StyledItem, StyledOptionTextContainer, StyledTrigger, useSelect } from "./utils"
import { commonLocations, timeZones } from "@ninjaone/webapp/src/js/includes/common/utils"

import { mapCommonLocationsTimezoneToOption, mapLocationsTimezoneToOption, timezoneMap } from "./utils/timezone"

const CustomStyledOptionTextContainer = styled(StyledOptionTextContainer)`
  width: 100%;
  display: flex;
  justify-content: space-between;

  span:last-child {
    color: ${({ theme }) => theme.colorTextWeakest};
  }
`

const StyledSeparator = styled(Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.colorBorderWeak};
`

const listboxMinWidth = 300
const triggerMinWidth = 240

const SelectOptions = ({ options }) => {
  return (
    <>
      {options.map(({ value: _value, labelText, timezone, offset, currentTime }, index) => (
        <StyledItem
          {...{
            value: _value,
            key: `${index}-${_value}`,
            "data-ninja-select-item": "",
          }}
          onClick={e => e.stopPropagation()}
        >
          <ItemText asChild>
            <CustomStyledOptionTextContainer {...{ listboxMinWidth }}>
              <span>
                {timezone} {labelText}
              </span>

              <span>{offset ?? currentTime}</span>
            </CustomStyledOptionTextContainer>
          </ItemText>
        </StyledItem>
      ))}
    </>
  )
}

export default function SelectTimezone({
  disabled,
  onChange,
  labelText,
  labelToken,
  alignRight,
  defaultValue,
  listboxZIndex,
  triggerAriaLabel,
  placeholderToken,
  customCommonOptions = [],
  hideLocalDeviceTimeOption,
  popoverProps = { portal: false },
  required,
  tooltipText,
}) {
  const ariaId = useMemo(() => uuidv4(), [])
  const momentCurrentTimezone = timezoneMap["current_location"]

  const currentLocationOption = {
    value: momentCurrentTimezone,
    currentTime: getTimeStringForTimezone(momentCurrentTimezone),
    labelText: `${localized("Current location")} - ${momentCurrentTimezone}`,
  }

  const commonLocationsParsed = commonLocations
    .filter(tz => tz.timeZoneId !== "current_location")
    .map(mapCommonLocationsTimezoneToOption)

  const commonTimezonesParsed = [
    ...customCommonOptions,
    ...(hideLocalDeviceTimeOption ? [] : [currentLocationOption]),
    ...commonLocationsParsed,
  ]
  const timezonesParsed = timeZones
    .filter(tz => tz.timeZoneId !== momentCurrentTimezone)
    .map(mapLocationsTimezoneToOption)

  const allOptions = [...commonTimezonesParsed, ...timezonesParsed]

  const { handleOnValueChange, handleOnOpenChange, valueToUse, valueLabel } = useSelect({
    defaultValue: defaultValue === "current_location" ? momentCurrentTimezone : defaultValue,
    options: allOptions,
    onChange,
  })

  const _SelectContent = (
    <StyledBaseContent
      {...{
        listboxZIndex,
        listboxMinWidth,
        position: "popper",
        "data-ninja-select-content": "",
        optionsLength: allOptions?.length,
        align: alignRight ? "end" : "start",
      }}
    >
      <Viewport>
        <SelectOptions {...{ options: commonTimezonesParsed }} />

        <StyledSeparator />

        <SelectOptions {...{ options: timezonesParsed }} />
      </Viewport>
    </StyledBaseContent>
  )

  return (
    <Root
      {...{
        disabled,
        value: valueToUse,
        onOpenChange: handleOnOpenChange,
        onValueChange: handleOnValueChange,
      }}
    >
      {labelText || labelToken ? (
        <SelectLabelComponent {...{ ariaId, disabled, labelText, labelToken, required, tooltipText }} />
      ) : null}

      <StyledTrigger
        {...{
          triggerMinWidth,
          "data-value": valueToUse,
          "data-ninja-select-trigger": "",
          onClick: e => e.stopPropagation(),
          "aria-required": !disabled && required,
          ...(labelText || labelToken
            ? {
                "aria-labelledby": ariaId,
              }
            : {
                "aria-label": triggerAriaLabel,
              }),
        }}
      >
        {/* || "" because we can't pass undefined as a children for a component. */}
        <SelectValueComponent {...{ placeholderToken }}>{valueLabel || ""}</SelectValueComponent>

        <SelectIconComponent />
      </StyledTrigger>

      {popoverProps?.portal ? <Portal>{_SelectContent}</Portal> : _SelectContent}
    </Root>
  )
}

SelectTimezone.propTypes = {
  /**
   * The ID for the label.
   */
  labelId: PropTypes.string,
  /**
   * Controls the disabled state of the component.
   */
  disabled: PropTypes.bool,
  /**
   * Event handler run when a timezone is selected.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The text to be displayed as the label.
   */
  labelText: PropTypes.string,
  /**
   * The token for the text to be displayed as the label.
   */
  labelToken: PropTypes.string,
  /**
   * Align the listbox on the right side of the trigger.
   */
  alignRight: PropTypes.bool,
  /**
   * The default value for the Select.
   */
  defaultValue: PropTypes.any,
  /**
   * The z-index for the listbox
   */
  listboxZIndex: PropTypes.number,
  /**
   * A custom ARIA label for the trigger.
   */
  triggerAriaLabel: PropTypes.string,
  /**
   * A token for the placeholder text.
   */
  placeholderToken: PropTypes.string,
  /**
   * Hide the local time option.
   */
  hideLocalDeviceTimeOption: PropTypes.bool,
  /**
   * Popover settings.
   */
  popoverProps: PropTypes.shape({
    portal: PropTypes.bool,
  }),
  /**
   * Custom options for the common options list.
   */
  customCommonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      currentTime: PropTypes.string,
      labelText: PropTypes.string,
    }),
  ),
  /**
   * The required state for the component.
   */
  required: PropTypes.bool,
  /**
   * The text for the more information tooltip in the component's label.
   */
  tooltipText: PropTypes.string,
}
