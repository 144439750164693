import { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import pathParse from "path-parse"
import { AlertMessage, Input, Select, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box, Flex, StyledAnchor } from "js/includes/components/Styled"
import { bytes, isFeatureEnabled, localizationKey, localized } from "js/includes/common/utils"
import { Attachment } from "js/includes/components/Attachment"
import { useMountedState } from "js/includes/common/hooks"
import {
  fileExtensions,
  getInstallerOptions,
  INSTALLER_FILE,
  INSTALLER_URL,
  validateFile,
} from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/utils"

const StyledInstallerContainer = styled(Flex)`
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${sizer(2)};
  }
`

const StyledHiddenInput = styled.input`
  display: none !important;
`

export default function InstallerSection({
  validation,
  os,
  url,
  onChange,
  updateValidate,
  installApplicationValidations,
  fileForAttachment,
  installerErrorMessage,
  setInstallerErrorMessage,
}) {
  const inputRef = useRef(null)
  const [uploadType, setUploadType] = useMountedState(INSTALLER_FILE)
  const [showInstallerContent, setShowInstallerContent] = useMountedState(true)
  const [loadingFile, setLoadingFile] = useMountedState(false)

  const maxFileSize = isFeatureEnabled("skip_opswat_for_installer_automation_uploads") ? 25 : 1

  useEffect(() => {
    if (!fileForAttachment) {
      setInstallerErrorMessage("")
    }
    setShowInstallerContent(!fileForAttachment)
  }, [fileForAttachment, uploadType, setInstallerErrorMessage, setShowInstallerContent])

  useEffect(() => {
    if (url) {
      setUploadType(INSTALLER_URL)
    }
  }, [url, setUploadType])

  const handleFileChange = async e => {
    setLoadingFile(true)
    e.preventDefault()
    try {
      const [file] = e.target.files
      const { success, message } = validateFile({ file, os, maxFileSize: maxFileSize * 1000 })
      if (success && file) {
        const { name, ext } = pathParse(file.name)
        const attachment = {
          file,
          uploadStatus: "SUCCESS",
          metadata: {
            name,
            mimeType: file.type,
            size: bytes(file.size),
            sizeBytes: file.size,
            extension: ext.replace(".", "").toLowerCase(),
          },
        }
        onChange({ file: attachment })
        setShowInstallerContent(false)
      } else {
        setInstallerErrorMessage(message)
      }
    } catch (e) {
      setInstallerErrorMessage(localized("Something went wrong. Please try again."))
    } finally {
      setLoadingFile(false)
    }
  }

  const handleFileRemoval = uploadType => {
    onChange({ file: null, url: "" })
    setInstallerErrorMessage("")
    setShowInstallerContent(true)
    updateValidate(installApplicationValidations(uploadType))
  }

  return (
    <>
      <Box margin={sizer(2, 0, 1)}>
        <Text bold size="sm" token={localizationKey("Installer")} />
      </Box>
      {showInstallerContent && !loadingFile && (
        <>
          <StyledInstallerContainer>
            <Select
              {...{
                options: getInstallerOptions(),
                value: uploadType,
                onChange: uploadType => {
                  setUploadType(uploadType)
                  handleFileRemoval(uploadType)
                },
                size: "sm",
                triggerAriaLabel: localized("Upload type"),
              }}
            >
              <Text bold size="sm" token={localizationKey("Upload type")} />
            </Select>
            {uploadType === INSTALLER_FILE ? (
              <>
                <StyledAnchor onClick={() => inputRef.current.click()}>
                  <StyledHiddenInput
                    data-testid="installer-file-input"
                    ref={inputRef}
                    onChange={handleFileChange}
                    type="file"
                    accept={fileExtensions[os]}
                  />
                  <Text bold size="sm" token={localizationKey("Choose installer file")} />
                </StyledAnchor>
                <Text bold size="sm" color="darkGray">
                  {localized("(Maximum file size: {{maxFileSize}} GB)", {
                    maxFileSize,
                  })}
                </Text>
              </>
            ) : (
              <Box width="100%">
                <Input
                  ariaLabel={localized("URL to installer")}
                  value={url}
                  onChange={e => onChange({ url: e.target.value.trim(), urlId: null })}
                  placeholder={localized("URL to installer")}
                  errorMessage={validation.message.url}
                />
              </Box>
            )}
          </StyledInstallerContainer>
          {installerErrorMessage && uploadType === INSTALLER_FILE && (
            <Box marginTop={sizer(1)}>
              <AlertMessage variant="danger" labelToken={installerErrorMessage}></AlertMessage>
            </Box>
          )}
        </>
      )}
      {(fileForAttachment || loadingFile) && uploadType === INSTALLER_FILE && (
        <Box marginTop={sizer(1)}>
          {
            <Attachment
              {...{
                width: "300px",
                download: false,
                key: fileForAttachment?.metadata?.name || "loadingFile",
                attachment: fileForAttachment,
                onDelete: () => handleFileRemoval(INSTALLER_FILE),
                loading: loadingFile,
              }}
            />
          }
        </Box>
      )}
    </>
  )
}
