/** @jsxImportSource @emotion/react */
import { PureComponent, createRef } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import {
  append,
  pluck,
  filter,
  findIndex,
  has,
  takeWhile,
  reject,
  map,
  slice,
  adjust,
  assoc,
  insertAll,
  inc,
  propEq,
  compose,
  when,
  not,
  find,
  flatten,
  concat,
  reduce,
  lt,
  dec,
  gt,
  flip,
  nth,
  defaultTo,
  insert,
  mergeDeepLeft,
  identity,
} from "ramda"
import { AutoSizer, List } from "react-virtualized"

import { ChevronDownLightIcon, ChevronRightIconLight } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { Skeleton, SearchBar } from "@ninjaone/components"

import { localized, transduce } from "js/includes/common/utils"

import { Box, Flex } from "./Styled"

const StyledToggle = styled.div`
  display: flex;
  cursor: ${props => (props.cursorDefault ? "default" : "pointer")};
  width: ${props => `${props.iconSize ?? "16px"}`};
  min-width: ${props => `${props.iconSize ?? "16px"}`};
  visibility: ${props => (props.hide ? "hidden" : "visible")};
`

const rowIconStyle = css`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const rowTextStyle = css`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  overflow: hidden;
  user-select: none;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledRowActions = styled.div`
  display: ${({ isFocusedRow }) => (isFocusedRow ? "block" : "none")};
`

export const StyledTreeRow = styled.div`
  height: 100%;
  display: flex;
  gap: ${tokens.spacing[2]};
  align-items: center;
  margin-left: ${props => props.marginLeft};

  padding: 0 ${tokens.spacing[2]};
  border-radius: ${tokens.borderRadius[1]};

  font-family: ${tokens.typography.fontFamily.primary};
  font-size: ${tokens.typography.fontSize.body};
  font-weight: ${tokens.typography.fontWeight.regular};

  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackground};

  &[data-selected="true"] {
    font-weight: ${tokens.typography.fontWeight.semiBold};
    color: ${({ theme }) => theme.colorTextActionStrong};
    background-color: ${({ theme }) => theme.colorForegroundSelected};

    svg {
      color: ${({ theme }) => theme.colorTextActionStrong};
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorForegroundHover};

    & .tree-row-actions {
      ${({ showActionsOnHover }) => showActionsOnHover && "display: block"};
    }
  }
`

const StyledRowContent = styled.div`
  flex: 1;
  height: 100%;
  min-width: 1px;
  display: flex;
  align-items: center;
  gap: ${tokens.spacing[2]};
`

const StyledTreeContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: ${({ minHeight }) => minHeight || "120px"};

  ${({ shrink }) =>
    shrink
      ? `
      width: 300px;  
      min-width: 300px;
      max-width: 300px;
      `
      : `
      width: 100%;
      `}
`

const StyledListContainer = styled.div`
  flex: 1 1;
  outline: none;

  &:focus-visible {
    .tree-row {
      &[data-selected="true"] {
        outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};
        outline-offset: -2px;
      }
    }
  }
`

const StyledTableContainer = styled.div`
  width: 100%;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${tokens.spacing[6]};

  width: 100%;
  height: 100%;
`

function setInternalRowProps({ tier, expandAllRows = false }) {
  return map(
    compose(
      when(row => row.children && expandAllRows, assoc("expanded", true)),
      when(row => row.tier === undefined, assoc("tier", tier)),
    ),
  )
}

function recursivelyPopulateChildren(onGetRowChildren) {
  return when(
    list => !!list.length,
    reduce(
      (acc, row) =>
        compose(
          concat([...acc, row]),
          recursivelyPopulateChildren(onGetRowChildren),
          setInternalRowProps({ tier: inc(row.tier), expandAllRows: true }),
          onGetRowChildren,
        )(row),
      [],
    ),
  )
}

export class Tree extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { loaded: false, list: [], visibleRows: [], filterText: "" }
    this.setList = this.setList.bind(this)
    this.onRowExpand = this.onRowExpand.bind(this)
    this.onRowCollapse = this.onRowCollapse.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
    this.insertUpdatedRows = this.insertUpdatedRows.bind(this)
    this.updateTreeData = this.updateTreeData.bind(this)
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this)
    this.onExpandAll = this.onExpandAll.bind(this)
    this.onCollapseAll = this.onCollapseAll.bind(this)
    this.getInitialList = this.getInitialList.bind(this)
    this.setFilterText = this.setFilterText.bind(this)
    this.listContainerRef = createRef()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async componentDidMount() {
    this._isMounted = true
    const initialList = this.getInitialList()
    await this.setList(initialList)
    if (this.props.searchable) {
      this.onExpandAll()
      this.onCollapseAll()
    }

    if (this._isMounted) {
      this.setState({ loaded: true })
    }
  }

  getInitialList() {
    const { expandAllRows = false, onGetRowChildren, onGetRoot } = this.props

    return compose(
      when(() => expandAllRows, recursivelyPopulateChildren(onGetRowChildren)),
      setInternalRowProps({ tier: 0, expandAllRows }),
      defaultTo([]),
      onGetRoot,
    )()
  }

  setList(list) {
    const {
      props: { onTreeListSet },
    } = this

    this.setState(
      {
        list,
        visibleRows: reject(propEq("hidden", true), list),
      },
      () => {
        onTreeListSet && onTreeListSet(list)
      },
    )
  }

  insertUpdatedRows({ updatedRows, listAfterIndex, isExpanded, index }) {
    const { list } = this.state
    const listBeforeCollapse = slice(0, index, list)
    const currentRow = assoc("expanded", isExpanded, list[index])
    const listAfterCollapse = slice(updatedRows.length, listAfterIndex.length, listAfterIndex)

    this.setList(flatten([listBeforeCollapse, currentRow, updatedRows, listAfterCollapse]))
  }

  onExpandAll() {
    const expandAllRows = compose(
      when(() => !this.props.expandAllRows, recursivelyPopulateChildren(this.props.onGetRowChildren)),
      setInternalRowProps({ expandAllRows: true }),
    )
    const initialList = this.getInitialList()
    this.updateTreeData(expandAllRows, initialList)
  }

  onCollapseAll() {
    const collapseRow = compose(
      when(row => !!row.tier, assoc("hidden", true)),
      assoc("expanded", false),
    )

    this.updateTreeData(map(collapseRow))
  }

  onRowCollapse(_index) {
    const { list, visibleRows } = this.state
    const index = findIndex(propEq("id", visibleRows[_index].id), list)
    const row = list[index]
    const listAfterIndex = slice(inc(index), list.length, list)

    const updatedRows = compose(
      map(assoc("hidden", true)),
      takeWhile(({ tier }) => tier > row.tier),
    )(listAfterIndex)

    this.insertUpdatedRows({
      updatedRows,
      listAfterIndex,
      isExpanded: false,
      index,
    })
  }

  async onRowExpand(_index, eventType) {
    const { list, visibleRows } = this.state
    const index = findIndex(propEq("id", visibleRows[_index].id), list)
    const row = list[index]

    if (!row.children) return

    if (eventType === "dblclick" && row.expanded) {
      this.onRowCollapse(_index)
      return
    }

    if (has("expanded", row)) {
      const listAfterIndex = slice(inc(index), list.length, list)

      const expandedRows = compose(append(row.id), pluck("id"), filter(propEq("expanded", true)))(list)

      function areAncestorsExpanded(parentId) {
        const parent = find(propEq("id", parentId), list)
        if (parent.parent_id) {
          return areAncestorsExpanded(parent.parent_id)
        }
        return parent.expanded || parent.id === row.id
      }

      let ignoreUntilTier
      const updatedRows = transduce(
        compose(
          map(_row => {
            const { tier, expanded, id, parent_id } = _row

            if (ignoreUntilTier) {
              if (ignoreUntilTier < tier) {
                /* row is under a collapsed parent */
                return assoc("hidden", true, _row)
              } else {
                ignoreUntilTier = undefined
              }
            }

            if (!parent_id) {
              /* row is root */
              return assoc("hidden", not(expanded || id === row.id), _row)
            }

            if (!expandedRows.includes(parent_id)) {
              /* row's parent is collapsed */
              return assoc("hidden", true, _row)
            }

            if (expanded === false) {
              /* row is collapsed; skip over all decendants */
              ignoreUntilTier = tier
            }

            /* row has parent, recursively check up tree to see if it should be visible */
            return assoc("hidden", not(areAncestorsExpanded(parent_id)), _row)
          }),
          takeWhile(({ tier }) => tier > row.tier),
        ),
        listAfterIndex,
      )

      this.insertUpdatedRows({
        updatedRows,
        listAfterIndex,
        isExpanded: true,
        index,
      })
    } else {
      const loadingRowId = `${row.id}-spinner`
      compose(
        this.setList,
        adjust(index, assoc("isExpanding", true)),
        insert(inc(index), { isSpinnerRow: true, id: loadingRowId, parent_id: row.id, tier: inc(row.tier) }),
      )(this.state.list)

      const children = await this.props.onGetRowChildren(row)

      const rowIndex = findIndex(propEq("id", row.id), this.state.list)
      compose(
        this.setList,
        filter(row => row.id !== loadingRowId),
        adjust(rowIndex, mergeDeepLeft({ expanded: true, isExpanding: false })),
        insertAll(inc(rowIndex), map(assoc("tier", inc(row.tier)), children)),
      )(this.state.list)
    }
  }

  onRowClick(row, index) {
    if (this.props.onRowClickHandler) {
      this.props.onRowClickHandler(row, index, {
        collapseRow: () => this.onRowCollapse(index),
        expandRow: () => this.onRowExpand(index),
      })
    }
  }

  onKeyDownHandler({ key, target }) {
    const {
      props: { onSelectionMove, focusedRow },
      state: { visibleRows },
      onRowCollapse,
      onRowExpand,
    } = this

    if (target !== this.listContainerRef.current) return

    if (!focusedRow) return this.onRowClick(visibleRows[0], 0)

    const index = findIndex(propEq("id", focusedRow), visibleRows)
    const lengthOfRows = visibleRows.length
    const atIndex = flip(nth)

    switch (key) {
      case "ArrowDown":
        when(compose(gt(lengthOfRows), inc), compose(onSelectionMove, atIndex(visibleRows), inc), index)
        break
      case "ArrowUp":
        when(compose(lt(-1), dec), compose(onSelectionMove, atIndex(visibleRows), dec), index)
        break
      case "ArrowLeft":
        onRowCollapse(index)
        break
      case "ArrowRight":
        onRowExpand(index)
        break
      default:
        break
    }
  }

  updateTreeData(transformer, list = this.state.list) {
    compose(this.setList, transformer)(list)
  }

  setFilterText(filterText, list = this.state.list) {
    this.setState({ filterText })
  }

  render() {
    const {
      state: { loaded, list, visibleRows, filterText },
      props: {
        onGetRowIcon,
        onGetRowText,
        focusedRow,
        rowHeight,
        actions,
        allowDoubleClick,
        className,
        rowClassName,
        rowStyle,
        rowIndent,
        searchRenderer,
        searchable,
        onGetRowChildren,
        onGetRowActions,
        onGetTable,
        ExpandedIcon,
        CollapsedIcon,
        iconSize,
        onGetRowStyle,
        showActionsOnHover = false,
        displayHeader = identity,
        minHeight,
        enableAutoHeight = false,
      },
      updateTreeData,
      onCollapseAll,
      onExpandAll,
      setFilterText,
    } = this

    let filteredRows = visibleRows

    const isSearchedRow = row =>
      searchable.some(key => row[key]?.toLowerCase().includes(filterText.trim().toLowerCase()))

    const isSearchedChildParentRow = row => row.children && onGetRowChildren(row).some(isSearchedRow)

    if (searchable?.length && filterText) {
      const getSearchedRows = reduce((acc, row) => {
        const isDirectSearch = isSearchedRow(row)
        const isParentSearch = isSearchedChildParentRow(row)

        if (isDirectSearch || isParentSearch) {
          acc.push({
            ...row,
            expanded: isParentSearch,
          })
        }

        return acc
      }, [])

      filteredRows = getSearchedRows(list)
    }

    const actionsContainer =
      actions && loaded ? (
        <Flex marginBottom={tokens.spacing[2]} gap={tokens.spacing[2]}>
          {actions.map((Action, index) => (
            <Action key={index} {...{ updateTreeData, onExpandAll, onCollapseAll }} />
          ))}
        </Flex>
      ) : null

    return (
      <StyledContainer>
        <StyledTreeContainer shrink={!!onGetTable} minHeight={minHeight}>
          {searchRenderer
            ? searchRenderer({ ...this.state, setFilterText })
            : !!searchable?.length && (
                <Box marginBottom={tokens.spacing[2]}>
                  <SearchBar
                    value={filterText}
                    placeholderText={localized("Search")}
                    onChange={({ target: { value } }) => {
                      setFilterText(value)
                    }}
                  />
                </Box>
              )}

          {displayHeader(actionsContainer)}

          <StyledListContainer
            ref={this.listContainerRef}
            data-testid="tree"
            role="grid"
            tabIndex={0}
            onKeyDown={this.onKeyDownHandler}
          >
            <AutoSizer {...(enableAutoHeight && { disableHeight: true })}>
              {({ width, height }) => (
                <List
                  tabIndex={-1}
                  onKeyDown={this.onKeyDownHandler}
                  className={className}
                  style={{ outline: "none" }}
                  width={width}
                  {...(enableAutoHeight ? { autoHeight: true, height: 1 } : { height })} // height of any value is needed when passing autoHeight
                  rowCount={filteredRows.length}
                  rowHeight={rowHeight}
                  rowRenderer={({ key, style, index }) => {
                    const row = filteredRows[index]
                    const isFocusedRow = focusedRow === row.id

                    return (
                      <Box {...{ key, style, className: rowClassName, ...rowStyle, ...onGetRowStyle?.(row) }}>
                        <StyledTreeRow
                          data-testid="tree-row"
                          className="tree-row"
                          onClick={e => this.onRowClick(row, index)}
                          onDoubleClick={e =>
                            allowDoubleClick &&
                            (row.expanded ? this.onRowCollapse(index) : this.onRowExpand(index, e.type))
                          }
                          data-selected={focusedRow && isFocusedRow ? "true" : "false"}
                          marginLeft={`${row.tier * rowIndent}px`}
                          showActionsOnHover={showActionsOnHover}
                        >
                          {!row.isSpinnerRow &&
                            (row.children ? (
                              row.isExpanding ? (
                                <StyledToggle cursorDefault {...{ iconSize }}>
                                  {ExpandedIcon ? (
                                    <ExpandedIcon />
                                  ) : (
                                    <ChevronDownLightIcon color="colorTextWeak" data-testid="expanded-icon" size="sm" />
                                  )}
                                </StyledToggle>
                              ) : row.expanded ? (
                                <StyledToggle onClick={e => this.onRowCollapse(index)} {...{ iconSize }}>
                                  {ExpandedIcon ? (
                                    <ExpandedIcon />
                                  ) : (
                                    <ChevronDownLightIcon color="colorTextWeak" data-testid="expanded-icon" size="sm" />
                                  )}
                                </StyledToggle>
                              ) : (
                                <StyledToggle
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.onRowExpand(index)
                                  }}
                                  {...{ iconSize }}
                                >
                                  {CollapsedIcon ? (
                                    <CollapsedIcon />
                                  ) : (
                                    <ChevronRightIconLight
                                      color="colorTextWeak"
                                      data-testid="collapsed-icon"
                                      size="sm"
                                    />
                                  )}
                                </StyledToggle>
                              )
                            ) : (
                              <StyledToggle cursorDefault hide {...{ iconSize }}>
                                {CollapsedIcon ? (
                                  <CollapsedIcon />
                                ) : (
                                  <ChevronRightIconLight color="colorTextWeak" data-testid="collapsed-icon" size="sm" />
                                )}
                              </StyledToggle>
                            ))}
                          <StyledRowContent>
                            {row.isSpinnerRow ? (
                              <Box display="flex" width="100%">
                                <Skeleton height="38px" width="100%" />
                              </Box>
                            ) : (
                              <>
                                {onGetRowIcon && <div css={rowIconStyle}>{onGetRowIcon(row)}</div>}
                                <div css={rowTextStyle}>{onGetRowText(row, { updateTreeData })}</div>
                                {onGetRowActions && loaded && (
                                  <StyledRowActions
                                    data-testid="tree-row-actions"
                                    className="tree-row-actions"
                                    {...{ isFocusedRow }}
                                  >
                                    {onGetRowActions(row, { updateTreeData })}
                                  </StyledRowActions>
                                )}
                              </>
                            )}
                          </StyledRowContent>
                        </StyledTreeRow>
                      </Box>
                    )
                  }}
                />
              )}
            </AutoSizer>
          </StyledListContainer>
        </StyledTreeContainer>
        {onGetTable && <StyledTableContainer>{onGetTable({ updateTreeData })}</StyledTableContainer>}
      </StyledContainer>
    )
  }
}

Tree.defaultProps = {
  rowIndent: 10,
}

Tree.propTypes = {
  rowStyle: PropTypes.object,
  rowIndent: PropTypes.number,
  rowHeight: PropTypes.number.isRequired,
  rowClassName: PropTypes.string,
  onGetRoot: PropTypes.func.isRequired,
  onGetRowChildren: PropTypes.func.isRequired,
  onGetRowIcon: PropTypes.func,
  onGetRowText: PropTypes.func.isRequired,
  onRowClickHandler: PropTypes.func,
  onSelectionMove: PropTypes.func,
  onTreeListSet: PropTypes.func,
  className: PropTypes.string,
  expandAllRows: PropTypes.bool,
  focusedRow: PropTypes.any,
  searchRenderer: PropTypes.func,
  searchable: PropTypes.array,
  searchTooltipText: PropTypes.string,
  ExpandedIcon: PropTypes.func,
  CollapsedIcon: PropTypes.func,
  iconSize: PropTypes.string,
}

export default Tree
