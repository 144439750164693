import { useCallback, useEffect, useMemo } from "react"
import { always, equals, evolve, when } from "ramda"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { Modal } from "@ninjaone/components"
import { createProduct, getProduct, updateProduct } from "js/includes/common/client"
import { useForm, useMountedState } from "js/includes/common/hooks"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  isUserAllowedToViewNinjaPSAProductPrice,
} from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { CREATE_PRODUCT, EDIT_PRODUCT } from "js/includes/configuration/integrations/psa/psaProducts/actions"
import { productTypes } from "js/includes/configuration/integrations/psa/psaProducts/productCommons"
import { Box } from "js/includes/components/Styled"
import { useProductCatalog } from "js/includes/common/utils/ninjaPSA"
import {
  LaborBilledProductContent,
  LaborTicketTimeEntryProductContent,
  ManagedDevicesProductContent,
  ProductGroupProductContent,
  SoftwareProductContent,
  UserProductContent,
} from "./content"
import { contentMapper, formActions, getFormValidations, formValuesMapper } from "./formCommons"

const formContentMapper = {
  [productTypes.USER_PRODUCT]: props => <UserProductContent {...props} />,
  [productTypes.SOFTWARE]: props => <SoftwareProductContent {...props} />,
  [productTypes.HARDWARE]: props => <SoftwareProductContent {...props} />,
  [productTypes.PRODUCT_GROUP]: props => <ProductGroupProductContent {...props} />,
  [productTypes.MANAGED_DEVICES]: props => <ManagedDevicesProductContent {...props} />,
  [productTypes.LABOR_BILLED]: props => <LaborBilledProductContent {...props} />,
  [productTypes.LABOR_TICKET_TIME_ENTRY]: props => <LaborTicketTimeEntryProductContent {...props} />,
  [productTypes.CUSTOM_PRODUCT]: props => <UserProductContent {...props} />,
}

const buildFormValidations = ({ productType, editDynamicQuantity, showMoneyFields }) => {
  return getFormValidations({ productType, editDynamicQuantity, showMoneyFields })
}

const omitPricingFields = ({ values, showMoneyFields }) =>
  when(
    () => !showMoneyFields,
    evolve({
      content: evolve({
        price: always(null),
        cost: always(null),
      }),
    }),
  )(values)

const ProductFormModal = ({
  product: _product,
  products: _products,
  action,
  unmount,
  afterSubmit,
  saveHandler,
  loadProduct = true,
  useDynamicQuantity = false,
  showDynamicQuantityValue = false,
  editDynamicQuantity = false,
  enabledFields,
  fieldLabelTokens,
  fieldTooltipInfoTokens,
  productFetcher,
  saveButtonText,
  currency,
  disabled,
}) => {
  const avoidFetchingProducts = !!_products || ![productTypes.PRODUCT_GROUP].includes(_product.type)
  const {
    loading: loadingProductCatalog,
    productCatalog,
    //TODO: refactor other areas of PSA that use the ProductForm component and pass down the product list to this component
  } = useProductCatalog(avoidFetchingProducts)

  const products = useMemo(() => _products || productCatalog, [_products, productCatalog])

  const [product, setProduct] = useMountedState(_product)
  const { productType, productInfo } = useMemo(() => {
    const productType = product.type
    return {
      productType,
      productInfo: {
        type: productType,
      },
    }
  }, [product.type])

  const updatingProduct = action === EDIT_PRODUCT.id
  const showMoneyFields = isUserAllowedToViewNinjaPSAProductPrice()

  const [isPersisting, setIsPersisting] = useMountedState(false)
  const [isProductFetched, setIsProductFetched] = useMountedState(!loadProduct)
  const [initialValues, setInitialValues] = useMountedState(formValuesMapper[productType]?.(product) || {})

  const initialValidations = useMemo(
    () => buildFormValidations({ productType, editDynamicQuantity, showMoneyFields }),
    [productType, editDynamicQuantity, showMoneyFields],
  )

  const { values, onChange, validateForm, validation, updateValidate, validateFormFields } = useForm({
    fields: initialValues,
    validate: initialValidations,
  })

  const fetchProduct = useCallback(
    async productId => {
      try {
        const productResponse = await (productFetcher?.(productId) ?? getProduct(productId))
        setProduct(productResponse)

        const newInitialValues = formValuesMapper[productResponse.type](productResponse)
        onChange(newInitialValues)
        setInitialValues(newInitialValues)
        updateValidate(buildFormValidations({ productType: productResponse.type, editDynamicQuantity }))
        setIsProductFetched(true)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching product"))
      }
    },
    [productFetcher, editDynamicQuantity, setProduct, setIsProductFetched, setInitialValues, onChange, updateValidate],
  )

  useEffect(() => {
    if (!product.id || !loadProduct) return
    fetchProduct(product.id)
  }, [product.id, loadProduct, fetchProduct])

  const handleSave = async e => {
    e.preventDefault()
    e.stopPropagation()
    let newProduct

    if (!validateForm() || ![CREATE_PRODUCT.id, EDIT_PRODUCT.id].includes(action)) return

    try {
      const productToPersist = omitPricingFields({
        showMoneyFields,
        values: contentMapper[productType](values, { editDynamicQuantity }),
      })

      if (saveHandler) {
        return saveHandler({ productToPersist, unmount, afterSubmit, setIsPersisting })
      }

      setIsPersisting(true)
      if (updatingProduct) {
        await updateProduct({
          productId: product.id,
          data: productToPersist,
        })
      } else {
        newProduct = await createProduct({
          data: productToPersist,
        })
      }

      showSuccessMessage(localized(formActions[action].successToken))
      afterSubmit?.(newProduct)
      unmount()
    } catch (error) {
      reportErrorAndShowMessage(
        error,
        error.resultCode === "duplicated_product_name"
          ? localizationKey("Product name already taken")
          : formActions[action]?.errorToken,
      )
      setIsPersisting(false)
    }
  }

  const handleClose = async () => {
    if (equals(initialValues, values)) return unmount()

    const response = await ShowMessageDialog({
      icon: { icon: faEdit, type: "critical" },
      title: localizationKey("You have unsaved changes"),
      message: localizationKey("Are you sure you wish to proceed?"),
      buttons: [
        { id: true, label: localizationKey("Continue") },
        { id: false, label: localizationKey("Cancel") },
      ],
    })

    return response && unmount()
  }

  return (
    <Modal
      size="lg"
      titleGroup={{
        titleToken: formActions[action]?.titleToken[productType] || "",
        descriptionToken: formActions[action]?.descriptionToken[productType] || "",
      }}
      cancelable
      closeAction={handleClose}
      unmount={unmount}
      buttons={[
        {
          labelText: saveButtonText || localized("Save"),
          onClick: handleSave,
          variant: "primary",
          disabled: isPersisting || disabled,
        },
      ]}
    >
      <Box height="550px">
        {(updatingProduct && !isProductFetched) || loadingProductCatalog ? (
          <Loading />
        ) : (
          formContentMapper[productType]?.({
            onChange,
            validation,
            values,
            products,
            productInfo,
            useDynamicQuantity,
            showDynamicQuantityValue,
            editDynamicQuantity,
            fieldLabelTokens,
            fieldTooltipInfoTokens,
            currency,
            validateFormFields,
            showMoneyFields,
            enabledFields: disabled ? {} : enabledFields,
          })
        )}
      </Box>
    </Modal>
  )
}

export default ProductFormModal
