import { filter, reject } from "ramda"
import { useMemo } from "react"
import { Modal, Select } from "@ninjaone/components"

import { useMountedState } from "js/includes/common/hooks"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import { productTypes } from "js/includes/configuration/integrations/psa/psaProducts/productCommons"

import { getProductTypeOptions } from "./formCommons"

const ProductTypeSelectorModal = ({ unmount, handleSelect, hiddenProductTypes, allowedProductTypes }) => {
  const [type, setType] = useMountedState(productTypes.HARDWARE)

  const productTypeOptions = useMemo(() => {
    const typeOptions = getProductTypeOptions()
    if (hiddenProductTypes?.length) {
      return reject(type => hiddenProductTypes.includes(type.value), typeOptions)
    } else if (allowedProductTypes?.length) {
      return filter(type => allowedProductTypes.includes(type.value), typeOptions)
    }
    return typeOptions
  }, [hiddenProductTypes, allowedProductTypes])

  const create = () => {
    handleSelect(type)
    unmount()
  }

  const onChangeType = newType => {
    setType(newType)
  }

  return (
    <Modal
      titleGroup={{
        titleText: localized("Product type"),
      }}
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Create"),
          onClick: create,
        },
      ]}
    >
      <Select
        value={type}
        options={productTypeOptions}
        labelId="product-type"
        labelText={localized("Type")}
        onChange={onChangeType}
        triggerAriaLabel={localized("Type")}
      />
    </Modal>
  )
}

export default ProductTypeSelectorModal
