import { useRef, useState } from "react"
import PropTypes from "prop-types"
import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import { Root, Trigger, Portal, Content } from "@radix-ui/react-popover"
import tokens from "@ninjaone/tokens"
import { isRequiredIf } from "@ninjaone/utils"
import { BulbOnIcon, ExternalLinkIconLight, VideoIconLight, QuestionCircleIconLight, CloseIcon } from "@ninjaone/icons"
import { Heading, Text, resetButtonStyle } from "@ninjaone/components"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

const getTriggerStylesFromSize = size => {
  if (size === "sm") {
    return `
      width: 24px;
      height: 24px;
    `
  } else if (size === "md") {
    return `
      width: 28px;
      height: 28px;
    `
  }
}

export const getSharedAnchorStyles = ({ theme, bold }) => `
  font-size: ${tokens.typography.fontSize.body};
  line-height: ${tokens.typography.lineHeight};
  text-decoration: none;
  text-wrap: balance;
  text-wrap: pretty; // use 'pretty' if supported, otherwise fallback to 'balance'
  font-weight: ${bold ? tokens.typography.fontWeight.medium : tokens.typography.fontWeight.regular};

  &:focus-visible {
    outline-color: ${theme.colorForegroundFocus};
  }
`

const StyledAnchor = styled.a`
  ${({ theme, bold }) => getSharedAnchorStyles({ theme, bold })}

  &&& {
    color: ${({ theme }) => theme.colorTextAction};
  }

  button:focus-visible & {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledButton = styled.button`
  ${resetButtonStyle}
  ${({ theme, bold }) => getSharedAnchorStyles({ theme, bold })}
  color: ${({ theme }) => theme.colorTextAction};
`

const StyledArticle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${tokens.spacing[3]};
  gap: ${tokens.spacing[2]};

  &:not(:last-child) {
    padding-bottom: ${tokens.spacing[3]};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  }
`

const StyledTimestamp = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing[1]};
  color: ${({ theme }) => theme.colorTextWeakest};
  font-size: ${tokens.typography.fontSize.body};
  height: 21px;
  font-variant-numeric: tabular-nums;
`

const StyledDescription = styled(Box)`
  button {
    ${resetButtonStyle}
    ${({ theme, bold }) => getSharedAnchorStyles({ theme, bold })}
    color: ${({ theme }) => theme.colorTextAction};
  }

  a {
    ${({ theme, bold }) => getSharedAnchorStyles({ theme, bold })}
    color: ${({ theme }) => theme.colorTextAction};
    white-space: nowrap;
  }
`

const StyledTitle = styled.div`
  display: flex;
  gap: ${tokens.spacing[1]};
  align-items: center;
  padding-bottom: ${tokens.spacing[3]};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  padding-right: 28px;
`

const StyledDiv = StyledAnchor.withComponent("div")

export const HelpTooltipVideoTimestamp = ({ timestamp }) => (
  <StyledTimestamp>
    <VideoIconLight />
    {timestamp}
  </StyledTimestamp>
)

const baseContentZIndex = 3000

const StyledBaseContent = styled(Content, {
  shouldForwardProp: prop => isPropValid(prop) || prop !== "triggerSize",
})`
  outline: none;
  padding-top: 1px; // avoids trigger outline from getting cutoff
  z-index: ${baseContentZIndex};
`

const StyledContent = styled.div`
  position: relative;
  width: 400px;
  padding: ${tokens.spacing[4]};
  border-radius: ${tokens.borderRadius[1]};
  border: ${({ theme }) => `1px solid ${theme.colorBorderWeak}`};
  background: ${({ theme }) => theme.colorBackgroundWidget};
  padding: ${({ contentPadding }) => contentPadding};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15); // replace box shadow with token
`

const StyledClose = styled.button`
  ${resetButtonStyle}
  position: absolute;
  top: ${tokens.spacing[4]};
  right: ${tokens.spacing[4]};
  height: 16px;
  color: ${({ theme }) => theme.colorTextStrong};

  &:focus-visible {
    outline-color: ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledTrigger = styled(Trigger)`
  position: relative;
  cursor: pointer;
`

const StyledIconButton = styled.button`
  ${resetButtonStyle}
  ${({ triggerSize }) => getTriggerStylesFromSize(triggerSize)}
  display: flex;
  justify-content: center;
  align-items: center;
`

const HelpHoverTooltip = ({ description, articles, footerDescription, triggerSize = "md", hideDelay = 750, Link }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hoveredOutsideWithMouse = useRef(false)
  const timeoutRef = useRef(null)
  const title = localized("Suggested help articles")
  const ArticleLink = Link

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setIsOpen(true)
  }

  const closeViaTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false)
      hoveredOutsideWithMouse.current = true
    }, hideDelay)
  }

  return (
    <Root open={isOpen}>
      <StyledTrigger
        {...{
          onMouseEnter: () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current)
            }

            setIsOpen(true)
          },
          onMouseLeave: closeViaTimeout,
          onClick: () => {
            if (isOpen) {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
              }
              setIsOpen(false)
            } else {
              setIsOpen(true)
            }
          },
          "data-ninja-hover-popover-trigger": "",
          triggerSize,
          asChild: true,
        }}
      >
        <StyledIconButton aria-label={title}>
          <QuestionCircleIconLight size={triggerSize} />
        </StyledIconButton>
      </StyledTrigger>

      <Portal>
        <StyledBaseContent
          {...{
            align: "start",
            alignOffset: 0,
            avoidCollisions: true,
            side: "bottom",
            sideOffset: 1,
            triggerSize,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: closeViaTimeout,
            onEscapeKeyDown: () => {
              setIsOpen(false)
            },
            onInteractOutside: event => {
              const eventIsFromTrigger = event.target.closest("[data-ninja-hover-popover-trigger]")
              if (!eventIsFromTrigger) {
                setIsOpen(false)
              }
            },
            onCloseAutoFocus: event => {
              if (hoveredOutsideWithMouse.current) {
                event.preventDefault()
                hoveredOutsideWithMouse.current = false
              }
            },
          }}
        >
          <StyledContent>
            {description && (
              <StyledDescription marginBottom={tokens.spacing[6]} paddingRight={tokens.spacing[8]}>
                <Text size="sm" color="colorTextStrong" textWrap>
                  {description}
                </Text>
              </StyledDescription>
            )}
            <StyledTitle>
              <BulbOnIcon color="colorTextWeak" />
              <Heading level={2} color="colorTextWeak" type="headingXs" bold>
                {title}
              </Heading>
            </StyledTitle>
            <div>
              {articles.map(({ id, label, labelToken, href, videoTimestamp, action }) => {
                const title = labelToken ? localized(labelToken) : label
                return (
                  <StyledArticle key={id || title}>
                    {action ? (
                      <StyledButton onClick={action}>{title}</StyledButton>
                    ) : ArticleLink ? (
                      <ArticleLink href={href}>
                        <StyledDiv>
                          {title} {!action && <ExternalLinkIconLight size="xs" />}
                        </StyledDiv>
                      </ArticleLink>
                    ) : (
                      <StyledAnchor {...{ href, target: "_blank", rel: "noopener noreferrer" }}>
                        {title} {!action && <ExternalLinkIconLight size="xs" />}
                      </StyledAnchor>
                    )}
                    {!!videoTimestamp && <HelpTooltipVideoTimestamp timestamp={videoTimestamp} />}
                  </StyledArticle>
                )
              })}
            </div>
            {footerDescription && (
              <StyledDescription marginTop={tokens.spacing[4]}>
                <Text size="sm" color="colorTextStrong" textWrap>
                  {footerDescription}
                </Text>
              </StyledDescription>
            )}
            <StyledClose
              aria-label="Close"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <CloseIcon />
            </StyledClose>
          </StyledContent>
        </StyledBaseContent>
      </Portal>
    </Root>
  )
}

HelpHoverTooltip.propTypes = {
  /**
   * The description for the tooltip.
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The list of articles that will be rendered in the tooltip.
   */
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      href: isRequiredIf(
        PropTypes.string,
        props => !props.hasOwnProperty("action"),
        "`href` is required if not passing a value for `action",
      ),
      action: isRequiredIf(
        PropTypes.func,
        props => !props.hasOwnProperty("href"),
        "`action` is required if not passing a value for `href",
      ),
      label: isRequiredIf(
        PropTypes.string,
        props => !props.hasOwnProperty("labelToken"),
        "`label` is required if not passing a value for `labelToken",
      ),
      labelToken: isRequiredIf(
        PropTypes.string,
        props => !props.hasOwnProperty("label"),
        "`labelToken` is required if not passing a value for `label",
      ),
      videoTimestamp: PropTypes.string,
    }),
  ).isRequired,
  /**
   * The description for the tooltip footer.
   */
  footerDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Icon size used for the trigger.
   */
  triggerSize: PropTypes.oneOf(["sm", "md"]),
}

export default HelpHoverTooltip
