import { memo, useEffect } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"

import { Button } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import { notifySales } from "js/includes/common/client/sales"
import { useMountedState } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { fetch, fetchJson, localized, ninjaReportError } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import UpgradeTrialAccountModal from "js/includes/components/UpgradeTrialAccountModal"

import TrialCountdownTitle from "./TrialCountdownTitle"

const StyledActionsList = styled.ul`
  display: flex;
  gap: ${sizer(2)};
  margin: 0;
  padding: 0;
  list-style: none;
`

const StyledContactBtn = styled(Button)`
  color: ${({ theme }) => theme.color.primary["100"]};
  border: ${({ theme }) => `1px solid ${theme.color.primary["100"]}`};
  font-weight: 500;
  padding: ${sizer(2, 3)};
  height: 32px !important;
`

const StyledBuyBtn = styled(Button)`
  font-weight: 500;
  padding: ${sizer(2, 3)};
  height: 32px !important;
`

const TrialCountdown = ({ expirationDate, divisionUid, accountCreatedDate }) => {
  const [{ accountOwnerEmail, accountOwnerName }, setAccountOwnerData] = useMountedState({
    accountOwnerEmail: "",
    accountOwnerName: "",
  })

  useEffect(() => {
    ;(async () => {
      try {
        const { salesforceContactEmail, salesforceContactName } = await fetchJson(
          `/division/trial-banner/contact-us/${divisionUid}`,
        )
        setAccountOwnerData({ accountOwnerEmail: salesforceContactEmail, accountOwnerName: salesforceContactName })
      } catch (error) {
        ninjaReportError(error)
      }
    })()
  }, [divisionUid, setAccountOwnerData])

  const handleContactUs = async () => {
    try {
      await fetch(`/division/trial-banner-contact-us-request-call/${divisionUid}`, { options: { method: "POST" } })
    } catch (error) {
      ninjaReportError(error)
    }
  }

  const buyNow = () => {
    showModal(
      <UpgradeTrialAccountModal
        email={accountOwnerEmail}
        contactName={accountOwnerName}
        titleToken={localized("Buy now")}
        message={localized(
          "Thank you! We've received your request and your product specialist will reach out shortly to complete your setup. Use the information below to contact us with any questions or requests.",
        )}
        onCall={() => notifySales(divisionUid)}
      />,
    )
  }

  const contactUs = () => {
    showModal(
      <UpgradeTrialAccountModal
        email={accountOwnerEmail}
        contactName={accountOwnerName}
        titleToken={localized("Contact us")}
        message={localized(
          "Use the information below to contact your product specialist or click the button to request a callback.",
        )}
        onCall={handleContactUs}
      />,
    )
  }

  return (
    <Flex data-testid="trial-countdown-container">
      <Flex>
        <TrialCountdownTitle expirationDate={expirationDate} accountCreatedDate={accountCreatedDate} />
        <StyledActionsList>
          <li>
            <StyledContactBtn variant="secondary" onClick={contactUs}>
              {localized("Contact")}
            </StyledContactBtn>
          </li>
          <li>
            <StyledBuyBtn variant="primary" onClick={buyNow}>
              {localized("Buy")}
            </StyledBuyBtn>
          </li>
        </StyledActionsList>
      </Flex>
    </Flex>
  )
}

export default connect(
  ({ session: { isTrialLicense, divisionUid, licenseExpirationDateTime, accountCreatedDate } }) => ({
    isTrialLicense,
    divisionUid,
    expirationDate: licenseExpirationDateTime,
    accountCreatedDate,
  }),
)(memo(TrialCountdown))
