import styled from "@emotion/styled"
import { cond, equals, always } from "ramda"
import PropTypes from "prop-types"

import tokens from "@ninjaone/tokens"
import { getTextSize, getLineHeight } from "@ninjaone/utils"
import { ExternalLinkIcon, RegularInfoCircleIconLight } from "@ninjaone/icons"

import { localizationKey, localized, localizedWith } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

//Need to use this link because some of the urls are for the DOJO and there is specific logic for those.
import WebappLink from "@ninjaone/webapp/src/js/includes/components/Link"
import Link from "./Link"
import Body from "./Typography/Body"

import HoverTooltip from "./HoverTooltip"

const StyledContainer = styled.div`
  display: flex;
  border-radius: 2px;
  user-select: none;
  white-space: nowrap;
  align-items: center;
  gap: ${tokens.spacing[1]};
  padding: ${`2px ${tokens.spacing[2]}`};
  background: linear-gradient(84deg, #053856 4%, #337ab7 64%, #007644 114%);
`

const StyledTrigger = styled.span`
  all: unset;
  cursor: pointer;
  display: inline-block;
`

const StyledMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing[4]};
`

const StyledText = styled.span`
  color: ${({ theme }) => theme.colorTextHighContrast};
  font-size: ${({ size }) => getTextSize(size)};
  line-height: ${getLineHeight("sm")};
`

const betaDescriptionToken = localizationKey(
  "<%appName> <%> is currently in beta and made available to select groups. To share your feedback with us, send an email to <%email> <%>.",
)

const earlyAccessDescriptionToken = localizationKey(
  "<%appName> <%> is currently in early access phase while final touches are being made. To share your feedback with us, send an email to <%email> <%>.",
)

const getLabel = cond([
  [equals("beta"), always("Beta")],
  [equals("earlyAccess"), always(localized("Early access"))],
])

const getDescriptionToken = cond([
  [equals("beta"), always(betaDescriptionToken)],
  [equals("earlyAccess"), always(earlyAccessDescriptionToken)],
])

export default function ReleaseStatus({
  size = "xs",
  type,
  appName,
  email = "app-feedback@ninjaone.com",
  knownIssuesLink,
}) {
  const text = (
    <StyledMessageContainer>
      {
        <Body textWrap as="span">
          {localizedWith(getDescriptionToken(type), {
            appName,
            email: () => (
              <Link onClick={e => e.stopPropagation()} href={`mailto: ${email}`}>
                {email}
              </Link>
            ),
          })}
        </Body>
      }
      {knownIssuesLink && (
        <Body textWrap as="span">
          {localizedWith("For a list of known issues, review our Help documentation. <%knownIssuesLink>Learn more<%>", {
            knownIssuesLink: ({ localizedText }) => (
              <WebappLink
                href={knownIssuesLink}
                target="_blank"
                rel="nonopener noreferrer"
                onClick={e => e.stopPropagation()}
              >
                {localizedText}
                <ExternalLinkIcon size="xs" />
              </WebappLink>
            ),
          })}
        </Body>
      )}
    </StyledMessageContainer>
  )

  return (
    <HoverTooltip
      sideOffset={4}
      textComponent={() => text}
      triggerComponent={() => (
        <StyledTrigger tabIndex={0}>
          <StyledContainer>
            <StyledText size={size}>{getLabel(type)}</StyledText>
            <RegularInfoCircleIconLight size="xs" color="colorTextHighContrast" />
          </StyledContainer>
        </StyledTrigger>
      )}
    />
  )
}

ReleaseStatus.propTypes = {
  email: PropTypes.string,
  knownIssuesLink: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm"]),
  type: PropTypes.oneOf(["beta", "earlyAccess"]).isRequired,
  appName: PropTypes.string.isRequired,
}
