import { fetchJson, getVersionHash } from "js/includes/common/utils"

// using a custom id for now since the old custom snmp table and the draggable table does not support custom row Ids
const addUid = oidConfigs =>
  oidConfigs.map(config => ({
    ...config,
    uid: `${config.oid}-${config.mib}`,
  }))

export const getAvailableOidsToImport = async () => {
  const oidConfigs = await fetchJson(`/custom-snmp/config?cacheKey=${getVersionHash()}`)
  return addUid(oidConfigs)
}

export const importOids = oidConfigs =>
  fetchJson("/oidtransformers/bulk", {
    options: {
      method: "POST",
      body: JSON.stringify(oidConfigs.map(({ uid, ...rest }) => rest)),
    },
  })

export const getImportedOids = async () => {
  const oidConfigs = await fetchJson("/oidtransformers/list")
  return addUid(oidConfigs)
}

export const addOid = oidConfig =>
  fetchJson("/oidtransformers", {
    options: {
      method: "POST",
      body: JSON.stringify(oidConfig),
    },
  })

export const editOid = ({ uid, ...rest }) =>
  fetchJson(`/oidtransformers/mib/${rest.mib}/oid/${rest.oid}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(rest),
    },
  })

export const deleteOid = ({ oid, mib }) =>
  fetchJson(`/oidtransformers/mib/${mib}/oid/${oid}`, {
    options: {
      method: "DELETE",
    },
  })
