import { Body, ConfirmationModal } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { localized, localizedWith } from "js/includes/common/utils"

const DeviceOfflineModal = ({ node, unmount }) => {
  const deviceName = node.name || node.friendlyName || node.systemName

  return (
    <ConfirmationModal
      {...{
        showActionButton: false,
        titleText: localized("Device is offline"),
        unmount,
      }}
    >
      <Body textWrap>
        <span data-testid="device-offline-modal-message">
          {localizedWith(
            "You can't connect or send commands to an offline device. This action requires <%deviceName>deviceName<%> to be online.",
            {
              deviceName: () => (
                <Body fontWeight={tokens.typography.fontWeight.semiBold} as="span" textWrap wordWrap="break-word">
                  {deviceName}
                </Body>
              ),
            },
          )}
        </span>
      </Body>
    </ConfirmationModal>
  )
}

export default DeviceOfflineModal
