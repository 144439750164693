import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"

const StyledMenuItem = styled.div`
  color: ${({ theme }) => theme.colorTextStrong};
  border-radius: ${tokens.borderRadius[1]};
  outline: none;

  &:focus,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }
`

const MenuItem = ({ onClick, id, hasSubMenu, children, onMouseMove, onMouseLeave, ...rest }) => {
  return (
    <StyledMenuItem
      {...{
        onClick,
        onMouseMove,
        onMouseLeave,
        role: "menuitem",
        tabIndex: "-1",
        id,
        ...rest,
      }}
      data-testid="menu-item"
    >
      {children}
    </StyledMenuItem>
  )
}

export default MenuItem
