import { useEffect, useMemo } from "react"
import { always, cond, either, isEmpty } from "ramda"

import { spacing } from "@ninjaone/tokens"
import { AlertMessage, Link, Text } from "@ninjaone/components"
import { useMountedState } from "@ninjaone/utils"

import {
  isAndroidDevice,
  isAppleMobileDevice,
  isMacDevice,
  localizationKey,
  localizedF,
  localizedWith,
  mapMDMConnectionOptions,
  ninjaReportError,
} from "js/includes/common/utils"
import { StyledLabel } from "js/includes/components/DeviceModal/style"
import { Box } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import {
  canCreateMDMConnection,
  canViewAtLeastOneConnection,
} from "js/includes/configuration/integrations/mdm/permissions"
import { MDMPermissionType } from "js/includes/configuration/integrations/mdm/constants"
import { fetchAPNConnections, fetchEnterpriseInformation } from "js/includes/common/client"
import { EntitySubType } from "js/includes/editors/User/Permissions/common"

export const getMDMValuesByClass = cond([
  [
    isAndroidDevice,
    always({
      getLabel: localizedF("Android Enterprise connection"),
      getConnections: fetchEnterpriseInformation,
      getLinkToConfigMessage: () =>
        localizedWith("To create an Android MDM connection navigate to <%link>Add Android Enterprise section<%>.", {
          link: ({ localizedText }) => <Link href="#/administration/apps/mdmAndroid">{localizedText}</Link>,
        }),
      permissionType: MDMPermissionType.Android,
      entitySubtype: EntitySubType.AndroidConnection,
      noConnectionsMessageToken: localizationKey("No Android MDM connections configured."),
    }),
  ],
  [
    either(isAppleMobileDevice, isMacDevice),
    always({
      getLabel: localizedF("APNs certificate"),
      getConnections: fetchAPNConnections,
      getLinkToConfigMessage: () =>
        localizedWith(
          "To create an APNs connection navigate to the <%link>Add Apple Push Notifications service section<%>.",
          {
            link: ({ localizedText }) => <Link href="#/administration/apps/mdmApple">{localizedText}</Link>,
          },
        ),
      permissionType: MDMPermissionType.AppleAPN,
      entitySubtype: EntitySubType.AppleAPNConnection,
      noConnectionsMessageToken: localizationKey("No Apple MDM connections configured."),
    }),
  ],
])

export default function MDMConnectionPicker({ installerType, disabled, onChange, selected }) {
  const [loadingConnections, setLoadingConnections] = useMountedState(false)
  const [connections, setConnections] = useMountedState([])

  const {
    getLabel,
    getConnections,
    getLinkToConfigMessage,
    permissionType,
    noConnectionsMessageToken,
    entitySubtype,
  } = useMemo(() => getMDMValuesByClass(installerType), [installerType])

  useEffect(() => {
    const getMDMConnections = async () => {
      setLoadingConnections(true)
      try {
        const androidConnections = await getConnections()
        const mappedConnections = mapMDMConnectionOptions(installerType, androidConnections ?? [])
        setConnections(mappedConnections)

        if (mappedConnections.length === 1) {
          onChange(mappedConnections[0])
        }
      } catch (error) {
        ninjaReportError(error)
      } finally {
        setLoadingConnections(false)
      }
    }
    canViewAtLeastOneConnection(entitySubtype) && getMDMConnections()
  }, [installerType, onChange, setConnections, setLoadingConnections, getConnections, entitySubtype])

  return (
    <Box marginTop={spacing[3]}>
      <StyledLabel htmlFor="mobile-device-connection-picker">
        {getLabel()}
        <span aria-hidden="true">&nbsp;*</span>
        <Text token={localizationKey("Required")} visuallyHidden />
      </StyledLabel>
      <SearchableDropDown
        {...{
          id: "mobile-device-connection-picker",
          disabled: loadingConnections || disabled || connections.length === 1,
          onSelect: onChange,
          options: connections,
          value: selected,
          searchPlaceholderToken: localizationKey("Select connection"),
          minHeight: "42px",
          width: "100%",
          rowHeight: 40,
          keepInView: false,
        }}
      />
      {!loadingConnections && isEmpty(connections) && (
        <Box marginTop={spacing[3]}>
          <AlertMessage titleToken={noConnectionsMessageToken} variant="info">
            {/* TODO: Probably the copy needs to be improved when global permissions will be implemented */}
            {canCreateMDMConnection(permissionType) && getLinkToConfigMessage()}
          </AlertMessage>
        </Box>
      )}
    </Box>
  )
}
