import { append, none, propEq, when } from "ramda"
import { SaasIconSvg } from "@ninjaone/icons"
import { APP_LOGOS, isITCloud, localizedF, user } from "js/includes/common/utils"
import { APPS_URL } from "js/includes/configuration/apps/appInfo"

export const SAAS_BACKUP_APP_NAME = "SAAS_BACKUP"

export const getSaasBackupAppConfig = () => ({
  label: () => "NinjaOne SaaS Backup",
  description: localizedF(
    "Backup important SaaS workloads such as e-mail and files in your Microsoft 365 and Google Workspace tenants." as never,
  ),
  logo: APP_LOGOS.NINJA,
  icon: <SaasIconSvg />,
  hide: () => isITCloud() || !user("canViewAndManageBackupData"),
  isNinja: true,
  configRoute: `${APPS_URL}/saas_backup`,
})

export const addDefaultSaasBackupApp = when(
  none(propEq("name", SAAS_BACKUP_APP_NAME)),
  append({ name: SAAS_BACKUP_APP_NAME, enabled: false }),
)
