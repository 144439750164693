import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { borderRadius, spacing } from "@ninjaone/tokens"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import ClipboardCopy from "./ClipboardCopy"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]};
  height: ${({ height, variant }) => (variant === "fixed" ? `${height}` : "auto")};
  max-height: ${({ height, variant }) => (variant === "fixed" ? `${height}` : "240px")};
  overflow-y: scroll;
  margin: 0;
  padding: ${({ copyable }) =>
    copyable ? `${spacing[2]} ${spacing[8]} ${spacing[2]} ${spacing[3]}` : `${spacing[2]} ${spacing[3]}`};
  border-radius: ${borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  list-style: none;
`

const getTextContent = children => {
  if (typeof children === "string") return children
  if (typeof children === "number") return String(children)
  if (Array.isArray(children)) {
    return children.map(getTextContent).join("")
  }
  if (children?.props?.children) {
    return getTextContent(children.props.children)
  }
  return ""
}

const ReadOnlyContainer = ({ as, children, copyable, height = "400px", variant = "responsive" }) => {
  return (
    <Box position="relative">
      {copyable && (
        <Box position="absolute" top={spacing[1]} right={spacing[1]}>
          <ClipboardCopy value={getTextContent(children)} transparent />
        </Box>
      )}
      <StyledContainer {...{ as, copyable, height, variant }} tabIndex={0}>
        {children}
      </StyledContainer>
    </Box>
  )
}

ReadOnlyContainer.propTypes = {
  /**
   * The HTML element to render the component as.
   */
  as: PropTypes.string,
  /**
   * The children to display in the component.
   */
  children: PropTypes.node,
  /**
   * Determines if ClipboardCopy should render in the component.
   */
  copyable: PropTypes.bool,
  /**
   * The height of the component when rendered as the fixed variant.
   */
  height: PropTypes.string,
  /**
   * The variant of the component.
   */
  variant: PropTypes.oneOf(["fixed", "responsive"]),
}

export default ReadOnlyContainer
