export const MDMConnectionType = {
  APN: "apn",
  ADE: "ade",
  ANDROID_ENTERPRISE: "enterprise",
  VPP: "vpp",
}

export const MDMPermissionType = {
  Android: "Android",
  AppleAPN: "AppleAPN",
  AppleADE: "AppleADE",
  VPP: "AppleAppsAndBooks",
}

export const MDMConnectionStatus = {
  ENROLLED: "ENROLLED",
  EXPIRED: "EXPIRED",
  RESETTING: "RESETTING",
  INVALID: "INVALID",
}

// WAMP events
// Received in this form:
// {
//   op: string (e.g.: "DELETE_INTEGRATION"),
//   connectionType: string (e.g.: "ANDROID_ENTERPRISE"),
//   commandType: string (e.g.: "DELETE_INTEGRATION"),
//   connectionName: string (e.g.: "Connection Name",
//   status: string (e.g.: "SUCCESS"),
//   errorCode: null | number,
//   errorMessage: null | string,
// }

export const MDM_CHANNEL = "mdm.connection.config"

export const WampCommandType = {
  DELETE_INTEGRATION: "DELETE_INTEGRATION",
  DELETE_CONTENT_TOKEN: "DELETE_CONTENT_TOKEN",
}

export const WampStatus = {
  SUCCESS: "SUCCESS",
}

export const WampConnectionType = {
  ANDROID_ENTERPRISE: "ANDROID_ENTERPRISE",
  APPLE_ADE: "APPLE_ADE",
  APPLE_APN: "APPLE_APN",
  APPLE_VPP: "APPLE_VPP_CONTENT_TOKEN",
}
