import { useRef } from "react"
import { connect, batch } from "react-redux"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Heading, Text } from "@ninjaone/components"
import { CircleExclamationIconLight, SearchIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import {
  resetGlobalSearchPageResults as _resetGlobalSearchPageResults,
  setGlobalSearchPageQuery as _setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery as _setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults as _setGlobalSearchPersistedResults,
} from "js/state/actions/globalSearch"
import { localized } from "js/includes/common/utils"
import { ResultsHeader, StyledAlert, StyledPopover } from "./common"
import GlobalSearchSkeletons from "./GlobalSearchSkeletons"
import GlobalSearchNoResultsAlert from "./GlobalSearchNoResultsAlert"
import GlobalSearchItem from "./GlobalSearchItem"
import { getGlobalSearchItemKey, getGlobalSearchPageUrl } from "./utils"

const StyledResultsLink = styled.a`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: ${getTextSize("sm")};
  margin-bottom: ${sizer(2)};
  border-radius: 4px;
  padding-left: ${sizer(3)};
  padding-right: ${sizer(3)};

  && {
    color: ${({ theme }) => theme.colorTextStrong};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:focus,
  &:focus-visible {
    outline-offset: -2px;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  svg {
    margin-right: 18px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const GlobalSearchPopover = ({
  searchValue,
  exactResults,
  suggestedResults,
  isLoading,
  closePopover,
  filter,
  showError,
  addRecentResult,
  resetSearch,
  resetGlobalSearchPageResults,
  setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults,
}) => {
  const popoverRef = useRef()
  const hasNoResults = exactResults?.length <= 0 && suggestedResults?.length <= 0

  const handleAddRecentResult = resultData => {
    const { matchAttr, matchAttrValue, ...restResultData } = resultData
    addRecentResult(restResultData)
  }

  const getResultsAlertText = () => {
    const hasOnlyExactResults = exactResults?.length && !suggestedResults?.length
    const hasOnlySuggestedResults = !exactResults?.length && suggestedResults?.length

    if (hasOnlyExactResults) {
      return localized("{{exactCount}} exact results found.", { exactCount: exactResults.length })
    }

    if (hasOnlySuggestedResults) {
      return localized("{{suggestedCount}} suggested results found.", {
        suggestedCount: suggestedResults.length,
      })
    }

    return localized("{{exactCount}} exact results found and {{suggestedCount}} suggested results found.", {
      exactCount: exactResults.length,
      suggestedCount: suggestedResults.length,
    })
  }

  if (isLoading)
    return (
      <StyledPopover {...{ isLoading: true, "data-testid": "global-search-popover" }}>
        <GlobalSearchSkeletons />
      </StyledPopover>
    )

  if (showError)
    return (
      <StyledPopover {...{ showAlert: true, "data-testid": "global-search-popover" }}>
        <StyledAlert role="alert">
          <CircleExclamationIconLight />
          <h3>{localized("Something went wrong on our end")}</h3>
          <p>{localized("Please refresh your page and try again")}</p>
        </StyledAlert>
      </StyledPopover>
    )

  if (hasNoResults)
    return (
      <StyledPopover {...{ showAlert: true, "data-testid": "global-search-popover" }}>
        <GlobalSearchNoResultsAlert {...{ filter, closePopover }} />
      </StyledPopover>
    )

  const resultsAlertText = getResultsAlertText()

  return (
    <StyledPopover ref={popoverRef} data-testid="global-search-popover">
      {!!resultsAlertText && (
        <Text visuallyHidden role="alert">
          {resultsAlertText}
        </Text>
      )}
      <div data-testid="global-search-results">
        <StyledResultsLink
          href={getGlobalSearchPageUrl({ searchValue, searchFilter: filter })}
          onClick={event => {
            const openingNewTab =
              event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)

            if (!openingNewTab) {
              batch(() => {
                setGlobalSearchPersistedResults(exactResults, suggestedResults)
                setGlobalSearchPersistedQuery(searchValue, filter)
                resetGlobalSearchPageResults()
                setGlobalSearchPageQuery(searchValue, filter)
              })
              closePopover()
            }
          }}
        >
          <SearchIcon fixedWidth={false} fontSize="20px" />
          <span>{localized(`View all results for "{{searchValue}}"`, { searchValue })}</span>
        </StyledResultsLink>
        {!!exactResults?.length && (
          <>
            <ResultsHeader breakoutOfContainer>
              <Heading type="headingXs" fontWeight={tokens.typography.fontWeight.regular} level={2}>
                {localized("Exact results")}
              </Heading>
            </ResultsHeader>
            <Box marginTop={sizer(3)} marginBottom={sizer(3)}>
              {exactResults.map(resultData => (
                <GlobalSearchItem
                  {...{
                    data: resultData,
                    key: getGlobalSearchItemKey(resultData),
                    closePopover,
                    searchValue,
                    onClick: () => {
                      handleAddRecentResult(resultData)
                      resetSearch(false)
                    },
                    containerRef: popoverRef,
                    itemIsInPopover: true,
                  }}
                />
              ))}
            </Box>
          </>
        )}
        {!!suggestedResults?.length && (
          <>
            <ResultsHeader breakoutOfContainer>
              <Heading type="headingXs" fontWeight={tokens.typography.fontWeight.regular} level={2}>
                {localized("Suggested results")}
              </Heading>
            </ResultsHeader>
            <Box marginTop={sizer(3)}>
              {suggestedResults.map(resultData => (
                <GlobalSearchItem
                  {...{
                    data: resultData,
                    key: getGlobalSearchItemKey(resultData),
                    closePopover,
                    searchValue,
                    onClick: () => {
                      handleAddRecentResult(resultData)
                      resetSearch(false)
                    },
                    containerRef: popoverRef,
                    itemIsInPopover: true,
                  }}
                />
              ))}
            </Box>
          </>
        )}
      </div>
    </StyledPopover>
  )
}

export default connect(null, {
  resetGlobalSearchPageResults: _resetGlobalSearchPageResults,
  setGlobalSearchPageQuery: _setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery: _setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults: _setGlobalSearchPersistedResults,
})(GlobalSearchPopover)
