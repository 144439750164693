import { Text } from "@ninjaone/components"
import { ChevronRightIconLight } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"

const RowItem = ({
  token,
  descriptionToken,
  descriptionId,
  icon,
  hasSubMenu = false,
  addMarginBottom = false,
  addMarginTop = false,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      cursor="pointer"
      padding={`6px ${tokens.spacing[3]}`}
      marginBottom={tokens.spacing[addMarginBottom ? 1 : 0]}
      marginTop={tokens.spacing[addMarginTop ? 1 : 0]}
      minWidth={220}
    >
      <Flex alignItems="center" marginRight={tokens.spacing[2]}>
        <Flex alignItems="center" marginRight={tokens.spacing[!!descriptionToken ? 3 : 2]}>
          {icon}
        </Flex>
        <Box>
          <Text {...{ token }} size="sm" />
          {descriptionToken && (
            <Text {...{ id: descriptionId, token: descriptionToken, color: "colorTextWeakest", size: "xs" }} />
          )}
        </Box>
      </Flex>
      {hasSubMenu && <ChevronRightIconLight size="sm" />}
    </Flex>
  )
}

export default RowItem
