import * as Sentry from "@sentry/browser"
import { Integrations as TracingIntegrations } from "@sentry/tracing"
import { anyPass, includes, __, assocPath } from "ramda"
import { NinjaNotAuthenticatedError, WampSessionNotAvailableError } from "js/includes/common/types"
import { betweenInclusive } from "js/includes/common/utils"
import { fetchSentryState } from "js/includes/common/client/sentry"

function isUserNotAuthenticatedError(exception) {
  return (
    !!exception &&
    (exception instanceof NinjaNotAuthenticatedError || exception instanceof WampSessionNotAvailableError)
  )
}

const sentryEnabledEnvs = [
  "app",
  "eu-central",
  "oc",
  "us2",
  "qa-release",
  "qa-hotfix",
  "qa-hotfix-2",
  "qa-3",
  "qa4",
  "qa5",
  "qa6",
  "staging",
]

export function isAllowedServerException(exception) {
  const responseStatusCode = exception?.status ?? exception?.response?.status ?? null
  if (responseStatusCode == null) return false

  return anyPass([betweenInclusive(400, 499), includes(__, [502, 0])])(responseStatusCode)
}

function isSentryEnabledEnv() {
  return process.env.NODE_ENV === "production" && sentryEnabledEnvs.includes(window.Sentry.ninjaEnvironment)
}

function replacer(match, route) {
  return `${route}/<id>`
}

function getFileLocationEnvOnly(frames) {
  return frames.map(frame => {
    const fileRoute = frame.filename.toString().split(`utf-8/`)[1]
    return { ...frame, filename: fileRoute ? fileRoute : frame.filename }
  })
}

function cleanTestCafeStacktrace(event) {
  const eventStacktraceFrames = event?.stacktrace?.frames ?? []
  if (eventStacktraceFrames.length) {
    const eventFileLocation = getFileLocationEnvOnly(eventStacktraceFrames)
    return assocPath(["stacktrace", "frames"], eventFileLocation, event)
  }

  const exceptionStacktraceframes = event?.exception?.values?.[0]?.stacktrace?.frames ?? []
  if (exceptionStacktraceframes.length) {
    const exceptionFileLocation = getFileLocationEnvOnly(exceptionStacktraceframes)
    return assocPath(["exception", "values", 0, "stacktrace", "frames"], exceptionFileLocation, event)
  }

  return event
}

export async function initializeSentry({ environment, sampleRate = 1, skipFetch = false, userType }) {
  if (!skipFetch) {
    const isSentryEnabled = await fetchSentryState(userType)
    if (!isSentryEnabled) {
      if (window?.Sentry?.close) {
        await window.Sentry.close(0)
      }
      delete window.Sentry
      return
    }
  }

  window.Sentry = Sentry
  window.Sentry.ninjaEnvironment = environment

  Sentry.init({
    sampleRate,
    integrations: [
      ...(isSentryEnabledEnv()
        ? [
            new TracingIntegrations.BrowserTracing({
              beforeNavigate: context => {
                return {
                  ...context,
                  name: window.location.hash
                    .replace(/(^#\/auth\/activate\/division)(\/{1}.+)/g, replacer)
                    .replace(/(^#\/auth\/activate\/user)(\/{1}.+)/g, replacer)
                    .replace(/(^#\/auth\/resetPassword)(\/{1}.+)/gi, replacer)
                    .replace(/(^#\/ticketing\/ticket\/new)(\/{1}.+)/g, replacer)
                    .replace(/(^#\/editor\/client-app)(\/{1}.+)/gi, replacer)
                    .replace(/\d+/g, "<digits>")
                    .replace(/[a-f0-9]{32}/g, "<hash>"),
                }
              },
            }),
          ]
        : []),
    ],
    tracesSampleRate: 0.01,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications",
      "ResizeObserver loop limit exceeded",
      "Object doesn't support property or method 'composedPath'", //happens when running CLI in Edge (non Chromium version)
      'can\'t redefine non-configurable property "userAgent"', //FireFox issue
      "SecurityError: SecurityError", // Edge issue
      "can't access dead object", //Firefox issue https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Dead_object
      "Non-Error promise rejection captured with value:", //Invalid errors with no exception information
      "window.grecaptcha.execute is not a function", //Recaptcha
      "recaptcha error: grecaptcha script not avialable", //Recaptcha
      "InvalidAccessError", //Wamp connection error
      "NetworkError when attempting to fetch resource",
      "Failed to fetch",
      "Loading CSS chunk",
      "LP_explicit_ignored",
      "Could not connect to the server",
      "Bad Gateway",
      "ChunkLoadError",
    ],
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment,
    beforeSend(event, { originalException }) {
      if (isAllowedServerException(originalException)) {
        console.log("Valid server exception. Not Reporting:", originalException)
        return null
      }

      if (isUserNotAuthenticatedError(originalException)) {
        console.log("User is not authenticated, exception is not being reported:", originalException)
        return null
      }

      if (!isSentryEnabledEnv()) {
        console.log("Not reporting error to Sentry in this environment:", originalException)
        return null
      }

      return cleanTestCafeStacktrace(event)
    },
  })
}
