export const setCwHelpDeskOptions = cwHelpDeskSettings => ({
  type: "SET_CW_HELPDESK_OPTIONS",
  cwHelpDeskSettings,
})

export const cwHelpDeskOptionsChangeActionCreator = dispatch => {
  return {
    setCreateContact: createContact => {
      dispatch({
        type: "SET_CW_HELPDESK_CREATE_CONTACT_OPTION",
        createContact,
      })
    },
  }
}
