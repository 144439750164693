import React, { useCallback, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"

import { v4 as uuidv4 } from "uuid"

import tokens from "@ninjaone/tokens"
import { isRequiredIf } from "@ninjaone/utils"

import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { isBackspaceKey, isEnterKey, isSpaceKey, localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Tags from "./Tags"
import Body from "./Typography/Body"
import { Label } from "./Form/Label"
import { ErrorIconTooltip } from "./IconTooltips"

const shouldIgnoreProps = prop => !["labelText", "errorMessage"].includes(prop)

const InputContainer = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  display: flex;
  justify-content: space-between;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "text")};

  width: 100%;
  height: 80px;

  position: relative;
  overflow: auto;
  scroll-padding-bottom: ${tokens.spacing[3]};

  border-radius: ${tokens.borderRadius[1]};
  border: 1px solid ${({ theme, errorMessage }) => (errorMessage ? theme.colorBorderError : theme.colorBorderWeak)};

  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colorBackgroundInputDisabled : theme.colorBackgroundInput};

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

const TagsContainer = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: ${tokens.spacing[2]};

  height: min-content;
  width: 100%;

  padding: ${tokens.spacing[3]} ${tokens.spacing[8]} ${tokens.spacing[3]} ${tokens.spacing[3]};
`

const ErrorIconContainer = styled.div`
  right: 8px;
  top: 8px;

  position: absolute;
`

const StyledInput = styled.input`
  ${({ disabled }) => disabled && `cursor: not-allowed;`}

  background: transparent;

  flex-grow: 1;
  height: 20px;

  border: none;
  outline: none;

  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => theme.colorTextStrong};

  ::placeholder {
    color: ${({ theme }) => theme.colorTextWeakest};
    font-size: ${tokens.typography.fontSize.body};
  }
`

function getParsedTag({ value, disabled, id, onRemoveClick }) {
  return {
    id,
    label: value,
    onRemoveClick,
  }
}

export default function CreatableInput({
  ariaLabel,
  disabled,
  errorMessage,
  labelText,
  maxTagsLength,
  onChange,
  onBlur,
  placeholderText,
  required,
  tooltipText,
  usesTagsCount,
  value: controlledValue,
  defaultValue,
}) {
  const inputRef = useRef()

  const [internalValue, setInternalValue] = useState(defaultValue)
  const value = controlledValue || internalValue

  const handleRemoveTag = useCallback(
    tagIndex => {
      if (disabled) return

      const newValue = value.filter((_, index) => index !== tagIndex)
      if (!controlledValue) {
        setInternalValue(newValue)
      }

      onChange(newValue)
    },
    [disabled, value, controlledValue, onChange],
  )

  const parsedTags = useMemo(() => {
    return value.map((currentControlledValue, index) =>
      getParsedTag({
        disabled,
        value: currentControlledValue,
        id: `${currentControlledValue}-${index}`,
        ...(!disabled && { onRemoveClick: () => handleRemoveTag(index) }),
      }),
    )
  }, [value, disabled, handleRemoveTag])

  const ariaId = useMemo(() => {
    return uuidv4()
  }, [])

  function handleRemoveLastTag(event) {
    if (isBackspaceKey(event) && event.target.value === "") {
      const newValue = value.slice(0, value.length - 1)

      if (!controlledValue) {
        setInternalValue(newValue)
      }

      onChange(newValue)
    }
  }

  function handleAddTag(event) {
    if (usesTagsCount && value.length >= maxTagsLength) return

    const newValue = event.target.value.replace(/\s+/g, "")
    if (!newValue) return

    if (!controlledValue) {
      if (value.includes(newValue)) return
      setInternalValue([...internalValue, newValue])
    }

    event.target.value = ""

    onChange([...value, newValue])
    event.preventDefault()
  }

  function handleTags(event) {
    if (isBackspaceKey(event) && event.target.value === "") {
      return handleRemoveLastTag(event)
    }

    if (isEnterKey(event) || isSpaceKey(event)) {
      return handleAddTag(event)
    }
  }

  function handleClearInputValue(event) {
    if (isEnterKey(event) || isSpaceKey(event)) {
      event.target.value = ""
    }
  }

  function parsedPlaceholder() {
    if (value.length) return
    if (placeholderText) return placeholderText

    return localized("Press Enter or Space to add")
  }

  return (
    <Flex {...{ flexDirection: "column", maxWidth: "980px" }}>
      {labelText && (
        <Label
          {...{
            disabled,
            required,
            labelText,
            tooltipText,
            labelFor: ariaId,
          }}
        />
      )}

      <InputContainer
        {...{
          disabled,
          tabIndex: 0,
          onBlur,
          errorMessage: !!errorMessage,
          ...(!disabled && { onClick: () => inputRef.current.focus() }),
        }}
      >
        <TagsContainer>
          {parsedTags.map(tag => (
            <React.Fragment {...{ key: tag?.id }}>
              <Tags {...{ labels: [tag], focusable: true }} />
            </React.Fragment>
          ))}

          <StyledInput
            {...{
              disabled,
              id: ariaId,
              onBlur: handleAddTag,
              onKeyDown: handleTags,
              onKeyUp: handleClearInputValue,
              placeholder: parsedPlaceholder(),
              ref: inputRef,
              type: "text",
              "aria-required": required,
              "aria-disabled": disabled,
              "aria-invalid": !!errorMessage,
              "aria-label": !labelText ? ariaLabel : undefined,
            }}
          />
        </TagsContainer>

        {!!errorMessage && (
          <ErrorIconContainer>
            <ErrorIconTooltip {...{ error: errorMessage, ariaId }} />
          </ErrorIconContainer>
        )}
      </InputContainer>

      {!disabled && usesTagsCount && (
        <Box {...{ marginTop: tokens.spacing[1], textAlign: "right" }}>
          <Body {...{ color: value?.length < maxTagsLength ? "colorTextWeakest" : "colorTextDanger" }}>
            {value?.length}/{maxTagsLength}
          </Body>
        </Box>
      )}
    </Flex>
  )
}

CreatableInput.propTypes = {
  /**
   * The ARIA label for accessibility.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.labelText,
    "ariaLabel is required when labelText is not provided",
  ),
  /**
   * The default value of the input.
   */
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  /**
   * Sets the disabled state of the component.
   */
  disabled: PropTypes.bool,
  /**
   * Sets the error state of the component.
   */
  errorMessage: PropTypes.string,
  /**
   * The label text for the select input.
   */
  labelText: PropTypes.string,
  /**
   * The maximum number of tags that can be added.
   */
  maxTagsLength: PropTypes.number,
  /**
   * The callback function when the value changes.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The callback function when the component loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * The placeholder text for the select input.
   */
  placeholderText: PropTypes.string,
  /**
   * Sets the required state of the component.
   */
  required: PropTypes.bool,
  /**
   * The text for the Tooltip in the Label of the the component.
   */
  tooltipText: PropTypes.string,
  /**
   * Determines if the tags count should be shown in the component. Used
   * in conjunction with the `maxTagsLength` prop.
   */
  usesTagsCount: PropTypes.bool,
  /**
   * The controlled value of the input.
   */
  value: PropTypes.arrayOf(PropTypes.string),
}

CreatableInput.defaultProps = {
  disabled: false,
  defaultValue: [],
  maxTagsLength: 20,
  usesTagsCount: false,
}
