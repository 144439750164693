import { find, propEq, reduce, map } from "ramda"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { fetchJson, showErrorMessage, localized, ninjaReportError } from "js/includes/common/utils"
import { show, hide } from "js/state/actions/common/visibility"

export const updateAccountOrganizationLocationSiteMapping = data => ({
  type: "EDIT_CW_ACCOUNT_ORGANIZATION_MODAL_MAPPING_LOCATION_SITE_MAPPING",
  data,
})

export const setLocationSiteModalSite = data => ({
  type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_SITE_MODAL_SITE",
  data,
})

export const setCompanySites = (companyId, sites) => ({
  type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_COMPANY_SITES",
  data: { companyId, sites },
})

export const clearCWAccountOrganizationSites = () => dispatch => {
  dispatch({
    type: "CLEAR_CW_ACCOUNT_ORGANIZATION_MODAL_COMPANY_SITES",
  })
}

export const setLocationSiteModalAvailableSites = data => ({
  type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_AVAILABLE_SITES",
  data,
})

export const setLocationSiteModalData = data => ({
  type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_SITE_MODAL",
  data,
})

export function setAvailableCompanySites(companyId) {
  return async (dispatch, getState) => {
    await dispatch(getCompanySites(companyId))
    const { accountOrganizationModal } = getState().psa.ConnectWise.mappings.accounts
    const availableSites = accountOrganizationModal.companySites[companyId]

    dispatch(setLocationSiteModalAvailableSites(availableSites))
  }
}

async function fetchCompanySites(companyId) {
  try {
    const { companySiteList } = await fetchJson(`/psacw/v2/company/companies/${companyId}/sites`)
    return companySiteList
  } catch (error) {
    throw error
  }
}

export function getCompanySites(companyId) {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading("CWLocationSiteLoading")())
      const { companySites } = getState().psa.ConnectWise.mappings.accounts.accountOrganizationModal
      if (!companySites[companyId]) {
        const companySiteList = await fetchCompanySites(companyId)
        dispatch(setCompanySites(companyId, companySiteList))
      }
    } catch (error) {
      showErrorMessage(localized("Error fetching company sites"))
      ninjaReportError(error)
    } finally {
      dispatch(stopLoading("CWLocationSiteLoading")())
    }
  }
}

export function autoMapLocations(companyId) {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading("CWPossibleLocationMappingsModal")())
      await dispatch(getCompanySites(companyId))
      const mappings = getState().psa.ConnectWise.mappings.accounts.accountOrganizationModal.mapping
        .locationSiteMappings
      const companySiteList = await fetchCompanySites(companyId)

      const possibleLocationMappings = reduce(
        (acc, mapping) => {
          const site = find(propEq("name", mapping.locationName), companySiteList)
          if (!site) return acc
          else {
            return [...acc, { mapping, site }]
          }
        },
        [],
        mappings,
      )

      dispatch({
        type: "SET_CW_ACCOUNT_POSSIBLE_LOCATION_MAPPINGS",
        possibleLocationMappings,
      })
    } catch (error) {
      showErrorMessage(localized("Error while auto mapping"))
      ninjaReportError(error)
    } finally {
      dispatch(stopLoading("CWPossibleLocationMappingsModal")())
    }
  }
}

export function refreshCompanySites(companyId) {
  return async dispatch => {
    try {
      dispatch(startLoading("CWLocationSiteLoading")())
      const companySiteList = await fetchCompanySites(companyId)
      dispatch(setCompanySites(companyId, companySiteList))
    } catch (error) {
      showErrorMessage(localized("Error while refreshing company sites"))
      ninjaReportError(error)
    } finally {
      dispatch(stopLoading("CWLocationSiteLoading")())
    }
  }
}

export function clearLocationMappings() {
  return (dispatch, getState) => {
    const mappings = getState().psa.ConnectWise.mappings.accounts.accountOrganizationModal.mapping.locationSiteMappings

    const newMappedLocations = map(mapping => {
      return {
        ...mapping,
        agreementId: null,
        agreementName: null,
        psaSiteId: null,
        psaSiteName: null,
        configurationTypeMappingId: null,
        configurationTypeMappingName: null,
        productMappingId: null,
        productMappingName: null,
        productMappingType: "CLIENT_DEFAULT",
        mapped: false,
      }
    }, mappings)

    dispatch({
      type: "SET_CW_ACCOUNT_LOCATION_MAPPINGS",
      newMappedLocations,
    })
  }
}

export function saveLocationSiteAutoMapping() {
  return (dispatch, getState) => {
    const possibleLocationMappings = getState().psa.ConnectWise.mappings.accounts.accountOrganizationModal
      .possibleLocationMappings
    const mappings = getState().psa.ConnectWise.mappings.accounts.accountOrganizationModal.mapping.locationSiteMappings
    const newMappedLocations = map(mapping => {
      const possibleLocationMapping = find(
        m => m.mapping.locationName === mapping.locationName,
        possibleLocationMappings,
      )
      if (possibleLocationMapping) {
        const {
          site: { id, name },
        } = possibleLocationMapping
        return {
          ...mapping,
          psaSiteId: id,
          psaSiteName: name,
          mapped: true,
        }
      }
      return mapping
    }, mappings)

    dispatch({
      type: "SET_CW_ACCOUNT_LOCATION_MAPPINGS",
      newMappedLocations,
    })
  }
}

export function cancelLocationSiteAutoMapping() {
  return dispatch => {
    dispatch({
      type: "CLEAR_CW_ACCOUNT_POSSIBLE_LOCATION_MAPPINGS",
    })
  }
}

export function updateLocationSiteMapping(data, companyId) {
  return dispatch => {
    dispatch(updateAccountOrganizationLocationSiteMapping(data))
  }
}

export function setSite(site) {
  return dispatch => {
    dispatch(setLocationSiteModalSite(site))
  }
}

export function setLocationProductMapping(data) {
  return dispatch => {
    dispatch({
      type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_PRODUCT_MAPPING",
      data,
    })
  }
}

export function setLocationAgreement(data) {
  return dispatch => {
    dispatch({
      type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_AGREEMENT",
      data,
    })
  }
}

export function setLocationConfigurationTypeMapping(data) {
  return dispatch => {
    dispatch({
      type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_CONFIGURATION_TYPE_MAPPING",
      data,
    })
  }
}

export function openLocationSiteMappingModal(location, companyId) {
  return dispatch => {
    dispatch(setLocationSiteModalData(location))
    dispatch(setAvailableCompanySites(companyId))
    dispatch(show("CWLocationSiteMappingModal")())
  }
}

export function closeLocationSiteMappingModa() {
  return dispatch => {
    dispatch(hide("CWLocationSiteMappingModal")())
  }
}
