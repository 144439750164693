import { localized, showErrorMessage, showSuccessMessage } from "js/includes/common/utils"
import { WampCommandType, WampConnectionType, WampStatus } from "./constants"

export const appsURL = "/administration/apps"
export const mdmAppleURL = `${appsURL}/mdmApple`
export const mdmAndroidURL = `${appsURL}/mdmAndroid`

export const tabQueryParamName = "tab"
export const MDMConfigTabKey = {
  APN: "apns",
  ADE: "ade",
  VPP: "vpp",
  AE: "ae",
}
export const defaultAPNsFilterQueryParamName = "defaultAPNsFilter"
export const nameFilterQueryParam = "namefilter"

/**
 * Show success / error message if wamp event matches target connectionName
 *
 * @param {Object} options - Object param
 * @param {Object} options.wamp - Wamp event
 * @param {Array} options.targetConnectionsList - Array of connections to target the `connectionName` of
 * @param {String} options.itemToken - Naming to be used for success / error messages
 * @param {String} [options.targetWampConnectionType=WampConnectionType.ANDROID_ENTERPRISE] - Wamp connection type to match, defaults to WampConnectionType.ANDROID_ENTERPRISE
 * @param {String} [options.targetNameKey="connectionName"] - Key to use to match name, defaults to 'connectionName'
 * @returns {String|null} Returns wamp's data connection name on successful match or null otherwise (either error or not matching at all the wamp event)
 */
export const showMessageOnWampDeleteIntegrationEvent = ({
  wamp,
  targetConnectionsList,
  itemToken,
  targetWampConnectionType = WampConnectionType.ANDROID_ENTERPRISE,
  targetNameKey = "connectionName",
  deleteCommandType = WampCommandType.DELETE_INTEGRATION,
}) => {
  const { commandType, status, connectionType, connectionName: wampConnectionName } = wamp?.data ?? {}

  if (
    commandType === deleteCommandType &&
    connectionType === targetWampConnectionType &&
    !!targetConnectionsList.find(connection => connection[targetNameKey] === wampConnectionName)
  ) {
    if (status === WampStatus.SUCCESS) {
      showSuccessMessage(
        localized("{{item}} with name {{name}} was deleted.", { item: localized(itemToken), name: wampConnectionName }),
      )
      return wampConnectionName
    } else {
      showErrorMessage(
        localized("There was an error deleting {{item}} with name {{name}}, please try again.", {
          item: localized(itemToken),
          name: wampConnectionName,
        }),
      )
    }
  }

  return null
}
