import PropTypes from "prop-types"
import { Body } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { ConfirmationModal } from "js/includes/components/ConfirmationModal"

const DisableMaintenanceConfirmationModal = ({ message, resolve, unmount }) => {
  return (
    <ConfirmationModal
      {...{
        type: "danger",
        titleToken: localizationKey("Disable maintenance?"),
        actionToken: localizationKey("Disable"),
        onConfirm: ({ unmount }) => {
          unmount()
          resolve(true)
        },
        onCancel: ({ unmount }) => {
          unmount()
          resolve(false)
        },
        unmount,
      }}
    >
      <Body textWrap>{message}</Body>
    </ConfirmationModal>
  )
}

DisableMaintenanceConfirmationModal.propTypes = {
  message: PropTypes.string,
  resolve: PropTypes.func,
  unmount: PropTypes.func,
}

export default DisableMaintenanceConfirmationModal
