import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { Body, Button, Heading, Modal } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import { DownloadIcon, LinkIcon } from "@ninjaone/icons"
import { spacing } from "@ninjaone/tokens"
import { useMountedState } from "@ninjaone/utils"

import { Box, Flex } from "js/includes/components/Styled"
import {
  clipboardWriteText,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  showErrorMessage,
  showSuccessMessage,
  validations,
} from "js/includes/common/utils"
import { AppUsersAndContactsDropdown } from "js/includes/ticketing/shared/components"
import { downloadEnrollmentFile, sendAppleEnrollment } from "js/includes/common/client"

const StyledGroup = styled(Flex)`
  flex-direction: column;
  gap: ${spacing[1]};
`

const StyledOl = styled.ol`
  padding-left: ${spacing[4]};
  list-style: decimal;
  color: ${({ theme }) => theme.colorTextWeak};
  display: flex;
  flex-direction: column;
`

export const MDMEnrollmentProfileModal = ({
  unmount,
  generatedData,
  clientId,
  pushCertificateId,
  locationId,
  nodeRole,
}) => {
  const [emails, setEmails] = useMountedState([])
  const [isLoading, setIsLoading] = useMountedState(false)

  const sendEmails = async () => {
    try {
      setIsLoading(true)
      const payload = emails.reduce(
        (acc, { isNew, uid }) => {
          acc[isNew ? "emails" : "uids"].push(uid)
          return acc
        },
        { emails: [], uids: [] },
      )

      await sendAppleEnrollment({
        pushCertificateId,
        deliverOption: "email",
        code: generatedData.code,
        payload,
      })
      showSuccessMessage(localized("The action was sent successfully."))
    } catch (error) {
      if (error.resultCode === "client_push_certificate_expired") {
        showErrorMessage(localized("An error has occurred. The Apple Push Notification certificate has expired."))
      } else {
        reportErrorAndShowMessage(
          error,
          localizationKey("There was an error sending the enrollment profile, please try again."),
        )
      }
    }
    setIsLoading(false)
  }

  const getEnrollmentProfile = async ({ copyURL = false } = {}) => {
    setIsLoading(true)
    await (copyURL
      ? clipboardWriteText(generatedData.url)
      : downloadEnrollmentFile({
          clientId,
          locationId,
          nodeRole,
          pushCertificateId,
        }))
    setIsLoading(false)
  }

  return (
    <Modal
      size="md"
      titleGroup={{
        titleText: localized("Profile successfully generated"),
        descriptionText: localized("Download or share the MDM enrollment profile."),
      }}
      showCloseButton={false}
      buttons={[
        {
          labelText: localized("Copy link"),
          Icon: LinkIcon,
          disabled: isLoading,
          onClick: () => getEnrollmentProfile({ copyURL: true }),
        },
        {
          labelText: localized("Download profile"),
          Icon: DownloadIcon,
          variant: "primary",
          disabled: isLoading,
          onClick: () => getEnrollmentProfile(),
        },
      ]}
      {...{ unmount }}
    >
      <Flex flexDirection="column" gap={spacing[2]}>
        <StyledGroup>
          <Heading level={2}>{localized("Steps for adding a device")}</Heading>

          <StyledOl>
            {[
              localized("Copy the profile to Mac."),
              localized("Install the profile."),
              localized("Follow on-screen instructions."),
            ].map(item => (
              <li key={item}>
                <Body color="colorTextWeak">{item}</Body>
              </li>
            ))}
          </StyledOl>
        </StyledGroup>

        <StyledGroup>
          <Heading level={2}>{localized("Send the MDM enrollment profile to your users")}</Heading>
          <Body color="colorTextWeak">
            {localized("Send it by email, share the link, or download the profile directly.")}
          </Body>

          <Flex gap={spacing[3]} alignItems="end" marginTop={spacing[4]}>
            <Box width="100%">
              <Label for="emails-input" labelText={localized("Select users to send the profile")} required />
              <AppUsersAndContactsDropdown
                id="emails-input"
                onSelect={setEmails}
                disabled={isLoading}
                shouldCreate={validations.email}
                isCreatable
                isMulti
                useSelectStyling
                {...{ clientId }}
              />
            </Box>
            <Button
              labelToken={localizationKey("Send")}
              disabled={isLoading || !emails.length}
              onClick={sendEmails}
              tooltip={emails.length ? "" : localized("Select a user to send this profile")}
            />
          </Flex>
        </StyledGroup>
      </Flex>
    </Modal>
  )
}

MDMEnrollmentProfileModal.propTypes = {
  generatedData: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  clientId: PropTypes.number.isRequired,
  pushCertificateId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  nodeRole: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
