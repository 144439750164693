import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { generateAppleEnrollmentFile, getInstallerUrl } from "js/includes/common/client"
import { useMounted, useMountedState } from "js/includes/common/hooks"
import AddDeviceOrganizationLocation from "js/includes/dashboards/AddDeviceOrganizationLocation"
import { isMacMDMFeatureEnabled, ninjaReportError } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import ModalLabeledSelector from "js/includes/components/ModalLabeledSelector"
import { NinjaResponseError } from "js/includes/common/types"
import DeviceModal from "js/includes/components/DeviceModal"
import DownloadInstallerModal from "js/includes/components/DeviceModal/DownloadInstallerModal"
import {
  getInstallerType,
  macPackageTypeOptions,
  linuxPackageTypeOptions,
  getLinuxArchitectureOptions,
  defaultLinuxArchitectureOption,
  defaultLinuxPackageType,
  defaultMacPackageType,
  computerOptions,
  computerHeadingTokenMapper,
  linkMapper,
  nodeClassesFromInstallerType,
} from "./helpers"
import MDMConnectionPicker from "js/includes/components/DeviceModal/MDMConnectionPicker"
import { MDMEnrollmentProfileModal } from "./MDM/MDMEnrollmentProfileModal"

const showInstallerModal = ({ url, installerSize }) => showModal(<DownloadInstallerModal {...{ url, installerSize }} />)

const showMDMEnrollmentProfileModal = data => {
  showModal(<MDMEnrollmentProfileModal {...data} />)
}

const AddComputerModalTokenized = ({ unmount }) => {
  const [installerType, setInstallerType] = useMountedState(computerOptions[0].value)
  const [macPackageType, setMacPackageType] = useMountedState(defaultMacPackageType)
  const [macMDMConnection, setMacMDMConnection] = useMountedState(null)
  const [linuxPackageType, setLinuxPackageType] = useMountedState(defaultLinuxPackageType)
  const [linuxArchitecture, setLinuxArchitecture] = useMountedState(defaultLinuxArchitectureOption)
  const [isGenerating, setIsGenerating] = useMountedState(false)
  const [location, setLocation] = useMountedState(null)
  const [organization, setOrganization] = useMountedState({})
  const [selectedNodeRole, setSelectedNodeRole] = useMountedState(null)
  const [showError, setShowError] = useMountedState(false)
  const mounted = useMounted()

  const distributionTypeOptions = isMacMDMFeatureEnabled()
    ? [...macPackageTypeOptions, { value: "MDM", label: "MDM enrollment profile" }]
    : macPackageTypeOptions

  const isMDMEnrollment = macPackageType.value === "MDM"
  const isMissingMDMConnection = isMDMEnrollment && !macMDMConnection

  const generateInstaller = async (organizationId, locationId, nodeRoleId) => {
    setShowError(false)
    setIsGenerating(true)

    try {
      if (isMDMEnrollment) {
        const callData = {
          clientId: organizationId,
          locationId,
          nodeRole: nodeRoleId,
          pushCertificateId: macMDMConnection.value,
        }
        const generatedData = await generateAppleEnrollmentFile(callData)

        showMDMEnrollmentProfileModal({
          generatedData,
          ...callData,
        })
        return
      }

      const typeOfInstaller = getInstallerType(
        installerType,
        installerType === "MAC"
          ? macPackageType
          : {
              package: linuxPackageType,
              architecture: linuxArchitecture,
            },
      )

      const response = await getInstallerUrl({
        organizationId,
        locationId,
        installerType: typeOfInstaller,
        nodeRoleId,
      })

      if (response.url) {
        mounted.current && showInstallerModal({ url: response.url, installerSize: response?.installerSize })
      } else {
        throw new NinjaResponseError(response)
      }
    } catch (error) {
      setShowError(true)
      ninjaReportError(error)
    } finally {
      setIsGenerating(false)
    }
  }

  return (
    <DeviceModal
      {...{
        unmount,
        titleGroup: {
          titleToken: localizationKey("Add a computer"),
          descriptionToken: localizationKey("Generate an installer to deploy to your endpoint."),
          link: linkMapper[installerType],
        },
        buttons: [
          {
            labelToken: localizationKey("Generate installer"),
            disabled: (!organization?.id && !location?.id) || isGenerating || isMissingMDMConnection,
            onClick: () => generateInstaller(organization.id, location.value, selectedNodeRole?.value ?? "auto"),
            variant: "primary",
          },
        ],
        showLoader: isGenerating,
        options: computerOptions,
        installerType,
        setInstallerType: type => {
          setShowError(false)
          setInstallerType(type)
          setSelectedNodeRole(null)
        },
        message: {
          show: showError,
          token: localizationKey("Error generating installer. Please try again."),
        },
        minHeight: "405px",
        optionsTitleToken: computerHeadingTokenMapper[installerType],
        OptionsComponent: (
          <>
            <AddDeviceOrganizationLocation
              {...{
                location,
                setLocation: location => {
                  setShowError(false)
                  setLocation(location)
                },
                organization,
                setOrganization: org => {
                  setShowError(false)
                  setOrganization(org)
                },
                selectedNodeRole,
                setSelectedNodeRole: node => {
                  setShowError(false)
                  setSelectedNodeRole(node)
                },
                generatingInstaller: isGenerating,
                installerNodeClasses: nodeClassesFromInstallerType[installerType],
              }}
            />
            {installerType === "MAC" && (
              <ModalLabeledSelector
                {...{
                  token: localizationKey("Distribution type"),
                  disabled: isGenerating,
                  options: distributionTypeOptions,
                  selected: macPackageType,
                  onChange: packageType => {
                    // Clear MDM connection if package type is not MDM
                    if (macMDMConnection && packageType.value === "MDM") {
                      setMacMDMConnection(null)
                    }
                    setShowError(false)
                    setMacPackageType(packageType)
                  },
                  testId: "distribution",
                  required: true,
                }}
              />
            )}
            {isMDMEnrollment && (
              <MDMConnectionPicker
                {...{
                  installerType,
                  selected: macMDMConnection,
                  onChange: setMacMDMConnection,
                }}
              />
            )}
            {installerType === "LINUX" && (
              <>
                <ModalLabeledSelector
                  {...{
                    token: localizationKey("Distribution type"),
                    disabled: isGenerating,
                    options: linuxPackageTypeOptions,
                    selected: linuxPackageType,
                    onChange: packageType => {
                      setShowError(false)
                      setLinuxPackageType(packageType)
                    },
                    testId: "distribution",
                    required: true,
                  }}
                />
                <ModalLabeledSelector
                  {...{
                    token: localizationKey("Architecture"),
                    disabled: isGenerating,
                    options: getLinuxArchitectureOptions(),
                    selected: linuxArchitecture,
                    onChange: computerArchitecture => {
                      setShowError(false)
                      setLinuxArchitecture(computerArchitecture)
                    },
                    testId: "computer-platform",
                    required: true,
                  }}
                />
              </>
            )}
          </>
        ),
      }}
    />
  )
}

export default AddComputerModalTokenized
