import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { GuideTooltip, IconButton } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

const DESCRIPTION_ID = "add-guide-tooltip-description"

const AddDropdownTrigger = ({
  id,
  addMenuId,
  showGuideTooltip,
  isActive,
  handleClick,
  onGuideTooltipClose,
  buttonRef,
}) => {
  const tooltipTitle = localized("Task: Add a device")
  const tooltipDescription = localized(
    "Click Device from the dropdown and select the device type you would like to deploy Ninja to.",
  )

  const commonProps = {
    icon: faPlus,
    handleClick,
    active: isActive,
    size: "lg",
    ref: buttonRef,
    type: "button",
    id,
    "aria-haspopup": "menu",
    "aria-expanded": isActive,
    "aria-controls": addMenuId,
  }

  if (showGuideTooltip) {
    return (
      <GuideTooltip
        {...{
          position: "bottom",
          align: "end",
          width: 400,
          title: tooltipTitle,
          iconName: "BulbIconLight",
          textRenderer: () => <p id={DESCRIPTION_ID}>{tooltipDescription}</p>,
          anchorRenderer: () => (
            <IconButton
              {...{
                icon: faPlus,
                "aria-label": tooltipTitle,
                "aria-describedby": DESCRIPTION_ID,
                ...commonProps,
              }}
            />
          ),
          onClose: onGuideTooltipClose,
          usesPortal: false,
          alignOffset: -10,
          sideOffset: 10,
        }}
      />
    )
  }

  return (
    <IconButton
      {...{
        tooltip: localized("Add"),
        hideTooltip: isActive,
        ...commonProps,
      }}
    />
  )
}

export default AddDropdownTrigger
