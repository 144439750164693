import React from "react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"
import { Box } from "js/includes/components/Styled"
import { Modal } from "@ninjaone/components"

const UpgradeTrialAccountModal = ({ unmount, onCall, email, contactName, titleToken, message }) => {
  const requestACall = async () => {
    onCall?.()
    unmount()
    await showModal(<RequestUpgradeAccountModal />)
  }

  return (
    <Modal
      unmount={unmount}
      titleGroup={{ titleToken: titleToken }}
      buttons={[{ type: "save", onClick: requestACall, labelToken: localizationKey("Request a Call") }]}
    >
      <Box textAlign="left">{localized(message)}</Box>

      <Box textAlign="left">
        {localized("Product Specialist")} <br /> {contactName}
      </Box>

      <Box textAlign="left">
        {localized("Email")} <br /> {!!email && <a href={`mailto:${email}`}>{email}</a>}
      </Box>
    </Modal>
  )
}

const RequestUpgradeAccountModal = ({ unmount }) => (
  <Modal unmount={unmount} titleGroup={{ titleToken: localizationKey("Request a Call") }}>
    <Box textAlign="left">
      {localized(
        "We have received your request for a callback. Your NinjaOne product specialist will call you shortly. Thank you!",
      )}
    </Box>
  </Modal>
)

export default UpgradeTrialAccountModal
