import { fetchJson } from "js/includes/common/utils"

export const createFreshserviceTicketTemplate = payload =>
  fetchJson("/psa/freshservice/ticket-template", {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })

export const updateFreshserviceTicketTemplate = (id, payload) =>
  fetchJson(`/psa/freshservice/ticket-template/${encodeURIComponent(id)}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(payload),
    },
  })

export const fetchFreshserviceTicketTemplates = () => fetchJson("/psa/freshservice/ticket-template")

export const fetchFreshserviceTicketTemplate = id =>
  fetchJson(`/psa/freshservice/ticket-template/${encodeURIComponent(id)}`)

export const deleteFreshserviceTicketTemplates = ticketTemplateIds =>
  fetchJson("/psa/freshservice/ticket-template/delete", {
    options: {
      method: "POST",
      body: JSON.stringify({ ticketTemplateIds }),
    },
  })
