import { ReactNode } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //TODO: revisit enabling this by default after updating to React Query v5
      // context -> https://github.com/TanStack/query/pull/4805
      refetchOnWindowFocus: false,
    },
  },
})

export const QueryProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={appQueryClient}>{children}</QueryClientProvider>
)

export const useFetchQuery = useQuery
