import { sizer } from "@ninjaone/utils"
import { Text } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import GlobalSearchItem from "./GlobalSearchItem"
import { StyledPopover } from "./common"
import { getGlobalSearchItemKey } from "./utils"
import tokens from "@ninjaone/tokens"

const GlobalSearchRecentsPopover = ({ recentResults, closePopover, addRecentResult }) => {
  return (
    <StyledPopover {...{ isRecentsPopover: true, "data-testid": "global-search-recents-popover" }}>
      <Text color="colorTextWeakest" fontWeight={tokens.typography.fontWeight.medium} size="sm">
        {localized("Recent searches")}
      </Text>
      <Box marginTop={sizer(3)}>
        {recentResults.map((resultData, index) => (
          <GlobalSearchItem
            {...{
              key: getGlobalSearchItemKey(resultData),
              data: resultData,
              closePopover,
              isRecentsItem: true,
              onClick: () => {
                addRecentResult(resultData)
              },
              itemIsInPopover: true,
            }}
          />
        ))}
      </Box>
    </StyledPopover>
  )
}

export default GlobalSearchRecentsPopover
