import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { connect, useDispatch } from "react-redux"

import {
  CalendarCheckIconLight,
  CloudIconLight,
  DesktopIconLight,
  FileChartPieIconLight,
  GlobeIconLight,
  MemoCircleCheckIconLight,
  MobileIconLight,
  ServerIconLight,
  SiteMapIconLight,
  TableColumnsIconLight,
  TicketIconLight,
  UserHelmetSafetyIconLight,
  UserIconLight,
  RouterIconLight,
} from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"

import showModal from "js/includes/common/services/showModal"
import {
  isFeatureEnabled,
  isUserAllowedToCreateTicket,
  localizationKey,
  localized,
  user,
  isMDMFeatureEnabled,
  isAppleMDMFeatureEnabled,
  isAndroidMDMFeatureEnabled,
} from "js/includes/common/utils"
import AddComputerModalLegacy from "js/includes/components/DeviceModal/AddComputerModal/AddComputerModalLegacy"
import AddComputerModalTokenized from "js/includes/components/DeviceModal/AddComputerModal/AddComputerModalTokenized"
import AddMobileDeviceModal from "js/includes/components/DeviceModal/AddMobileDeviceModal"
import AddVMModal from "js/includes/components/DeviceModal/AddVMModal"
import AddNMSModal from "js/includes/components/DeviceModal/AddNMSModal"
import { Box } from "js/includes/components/Styled"
import { showCreateTicketModal } from "js/includes/ticketing/CreateTicketModal"
import { getNewTicketPagePath } from "js/includes/ticketing/shared/utils/tickets"
import { closeQuickMenu } from "js/state/actions/general/quickMenus"
import { closeGuideTooltip } from "js/state/actions/general/guideTooltips"
import { NetworkDiscoveryModal } from "js/includes/networkDiscovery"
import { NetworkDiscoveryProvider } from "js/includes/networkDiscovery/NetworkDiscoveryContext"
import CreatePolicyModal from "js/includes/editors/Policy/Models/General/CreatePolicyModal"

import CustomDropdown from "./CustomDropdown"
import AddDropdownTrigger from "./AddDropdownTrigger"
import RowItem from "./RowItem"

const ADD_MENU_ID = "add-menu"
const ADD_MENU_TRIGGER_ID = "add-menu-trigger"
const ADD_DEVICE_TRIGGER_ID = "add-device-menu-trigger"
const ADD_USER_TRIGGER_ID = "add-user-menu-trigger"

const openAddComputerModal = () =>
  showModal(isFeatureEnabled("agent_tokenization") ? <AddComputerModalTokenized /> : <AddComputerModalLegacy />)

const openAddMobileDeviceModal = () =>
  showModal(<AddMobileDeviceModal />, { withProvider: true, withReactRouter: true })

const openAddVMModal = () => showModal(<AddVMModal />, { withProvider: true })

const openAddNMSModal = () => showModal(<AddNMSModal />)

const openNetworkDiscoveryModal = () =>
  showModal(
    <NetworkDiscoveryProvider>
      <NetworkDiscoveryModal />
    </NetworkDiscoveryProvider>,
    { withProvider: true },
  )

const openAddPolicyModal = () => showModal(<CreatePolicyModal />)

const getMobileDeviceDescriptionToken = () => {
  switch (true) {
    case isAppleMDMFeatureEnabled() && isAndroidMDMFeatureEnabled():
      return "Apple, Android"
    case isAppleMDMFeatureEnabled():
      return "Apple"
    case isAndroidMDMFeatureEnabled():
      return "Android"
    default:
      return ""
  }
}

const isKeyboardEvent = event => event.keyCode || event.code || event.detail === 0

const getOptions = ({ ticketingEnabledFromSettings, deviceOptionCustomProps, userOptionCustomProps }) => {
  const showTicketing = ticketingEnabledFromSettings && isUserAllowedToCreateTicket()

  return [
    {
      LabelComponent: () => <RowItem {...{ token: localizationKey("Ticket"), icon: <TicketIconLight /> }} />,
      ariaLabel: localized("Ticket"),
      action: () => (window.location.hash = getNewTicketPagePath({ withHashTag: true })),
      show: showTicketing,
    },
    {
      LabelComponent: () => (
        <RowItem {...{ token: localizationKey("Ticket from template"), icon: <TicketIconLight /> }} />
      ),
      ariaLabel: localized("Ticket from template"),
      action: showCreateTicketModal,
      show: showTicketing,
    },
    {
      LabelComponent: () => (
        <RowItem
          {...{ token: localizationKey("Ticket board"), icon: <TableColumnsIconLight />, addMarginBottom: true }}
        />
      ),
      ariaLabel: localized("Ticket board"),
      // TODO: open create new ticketing board
      action: () => (window.location.hash = "#/administration/apps/ticketing"),
      show: showTicketing,
      splitAfter: true,
    },
    {
      LabelComponent: () => (
        <RowItem
          {...{
            token: localizationKey("Device"),
            icon: <DesktopIconLight />,
            addMarginTop: showTicketing,
            hasSubMenu: true,
          }}
        />
      ),
      ariaLabel: localized("Device"),
      show: user("canCreateDevices"),
      hasSubMenu: true,
      id: ADD_DEVICE_TRIGGER_ID,
      ...deviceOptionCustomProps,
    },
    {
      LabelComponent: () => (
        <RowItem {...{ token: localizationKey("User"), icon: <UserIconLight />, hasSubMenu: true }} />
      ),
      ariaLabel: localized("User"),
      show: user("canCreateUsers") || user("canCRUDEndUserSharing"),
      hasSubMenu: true,
      id: ADD_USER_TRIGGER_ID,
      ...userOptionCustomProps,
    },
    {
      LabelComponent: () => <RowItem {...{ token: localizationKey("Report"), icon: <FileChartPieIconLight /> }} />,
      ariaLabel: localized("Report"),
      action: () => (window.location.hash = "#/reporting/reports"),
      show: user("canManageReports") && user("canViewAtLeastOneOrganization"),
    },
    {
      LabelComponent: () => <RowItem {...{ token: localizationKey("Organization"), icon: <SiteMapIconLight /> }} />,
      ariaLabel: localized("Organization"),
      action: () => (window.location.hash = "#/editor/customer"),
      show: user("canCreateCustomers"),
    },
    {
      LabelComponent: () => <RowItem {...{ token: localizationKey("Policy"), icon: <MemoCircleCheckIconLight /> }} />,
      ariaLabel: localized("Policy"),
      action: openAddPolicyModal,
      show: user("canCreatePolicies"),
    },
    {
      LabelComponent: () => (
        <RowItem {...{ token: localizationKey("Scheduled task"), icon: <CalendarCheckIconLight /> }} />
      ),
      ariaLabel: localized("Scheduled task"),
      action: () => (window.location.hash = "#/editor/scheduledTask"),
      show: user("canCreateScheduledTasks") && user("canRunAtLeastOneAdhocScript"),
    },
  ]
}

const getDeviceOptions = () => [
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("Computer"),
          descriptionToken: localizationKey("Windows, Linux, Mac"),
          descriptionId: "add-menu-computer-description",
          icon: <DesktopIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaDescribedBy: "add-menu-computer-description",
    ariaLabel: localized("Computer"),
    action: openAddComputerModal,
  },
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("Mobile device"),
          descriptionToken: getMobileDeviceDescriptionToken(),
          descriptionId: "add-menu-mobile-device-description",
          icon: <MobileIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("Mobile device"),
    ariaDescribedBy: "add-menu-mobile-device-description",
    action: openAddMobileDeviceModal,
    show: isMDMFeatureEnabled(),
  },
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("Virtual infrastructure"),
          descriptionToken: localizationKey("HyperV, VMWare"),
          descriptionId: "add-menu-virtual-infrastructure-description",
          // TODO: switch this icon to FA5 server icon
          icon: <ServerIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("Virtual infrastructure"),
    ariaDescribedBy: "add-menu-virtual-infrastructure-description",
    action: openAddVMModal,
  },
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("Cloud monitor"),
          descriptionToken: localizationKey("Ping, Port scan, DNS, HTTP/HTTPS"),
          descriptionId: "add-menu-cloud-monitor-description",
          icon: <CloudIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("Cloud monitor"),
    ariaDescribedBy: "add-menu-cloud-monitor-description",
    action: () => (window.location.hash = "#/editor/cloudMonitor"),
  },
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("NMS"),
          descriptionToken: localizationKey("Network Management System"),
          descriptionId: "add-menu-cloud-nms-description",
          icon: <GlobeIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("NMS"),
    ariaDescribedBy: "add-menu-cloud-nms-description",
    action: openAddNMSModal,
    show: !(isFeatureEnabled("nms_network_discovery") && isFeatureEnabled("custom_snmp")),
  },
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("Run Ad Hoc Network Discovery"),
          descriptionToken: localizationKey("Network management system"),
          descriptionId: "add-menu-cloud-nms-description",
          icon: <RouterIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("Run Ad Hoc Network Discovery"),
    ariaDescribedBy: "add-menu-cloud-nms-description",
    action: openNetworkDiscoveryModal,
    show: isFeatureEnabled("nms_network_discovery"),
  },
]

const getUserOptions = () => [
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("Technician"),
          descriptionToken: localizationKey("Add a technician to your team"),
          descriptionId: "add-menu-technician-description",
          icon: <UserHelmetSafetyIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("Technician"),
    ariaDescribedBy: "add-menu-technician-description",
    action: () => (window.location.hash = "#/editor/user"),
    show: user("canCreateUsers"),
  },
  {
    LabelComponent: () => (
      <RowItem
        {...{
          token: localizationKey("End user"),
          descriptionToken: localizationKey("Add an end user for a device"),
          descriptionId: "add-menu-end-user-description",
          icon: <UserIconLight fontSize={sizer(5)} />,
        }}
      />
    ),
    ariaLabel: localized("End user"),
    ariaDescribedBy: "add-menu-end-user-description",
    action: () => (window.location.hash = "#/editor/endUser?showOrganizationPicker=true"),
    show: user("canCRUDEndUserSharing"),
  },
]

const initialDropdownState = {
  show: false,
  openedFromKeyboard: false,
}

const AddDropdown = ({
  ticketingEnabledFromSettings,
  externalRequestToOpenUserDropdown,
  showAddDeviceMenuGuideTooltip,
}) => {
  const [rootDropdownState, setRootDropdownState] = useState(initialDropdownState)
  const [deviceDropdownState, setDeviceDropdownState] = useState(initialDropdownState)
  const [userDropdownState, setUserDropdownState] = useState(initialDropdownState)

  const wrapperRef = useRef()
  const buttonRef = useRef()
  const dispatch = useDispatch()

  const showRootDropdown = rootDropdownState.show || externalRequestToOpenUserDropdown
  const isSubMenuOpen = deviceDropdownState.show || userDropdownState.show

  useEffect(() => {
    if (externalRequestToOpenUserDropdown) {
      setUserDropdownState(({ show }) => ({ show: true, openedFromKeyboard: false }))
    }
  }, [externalRequestToOpenUserDropdown])

  const closeAddDeviceTooltip = () => dispatch(closeGuideTooltip("addDeviceMenu"))

  const focusBackToSubMenuTrigger = id => {
    const subMenuTrigger = wrapperRef.current.querySelector(`#${id}`)
    subMenuTrigger.focus()
  }

  const options = useMemo(() => {
    const deviceOptionCustomProps = {
      action: (openedFromKeyboard = false) =>
        setDeviceDropdownState(({ show }) => ({ show: !show, openedFromKeyboard })),
      isSubMenuOpen: deviceDropdownState.show,
    }

    const userOptionCustomProps = {
      action: (openedFromKeyboard = false) => setUserDropdownState(({ show }) => ({ show: !show, openedFromKeyboard })),
      isSubMenuOpen: userDropdownState.show,
    }

    return getOptions({
      ticketingEnabledFromSettings,
      deviceOptionCustomProps,
      userOptionCustomProps,
    })
  }, [ticketingEnabledFromSettings, deviceDropdownState.show, userDropdownState.show])

  const handleCloseMainDropdown = useCallback(
    closedFromKeyboard => {
      if (externalRequestToOpenUserDropdown) {
        dispatch(closeQuickMenu("add"))
      }

      setRootDropdownState(initialDropdownState)
      setDeviceDropdownState(initialDropdownState)
      setUserDropdownState(initialDropdownState)

      if (closedFromKeyboard) {
        buttonRef.current.focus()
      }
    },
    [dispatch, externalRequestToOpenUserDropdown],
  )

  return (
    <Box position="relative" ref={wrapperRef}>
      <AddDropdownTrigger
        {...{
          id: ADD_MENU_TRIGGER_ID,
          addMenuId: ADD_MENU_ID,
          showGuideTooltip: showAddDeviceMenuGuideTooltip,
          isActive: rootDropdownState.show || isSubMenuOpen,
          handleClick: event => {
            setRootDropdownState(({ show }) => ({
              show: !show,
              openedFromKeyboard: isKeyboardEvent(event),
            }))

            // sub menu is open and user is closing by clicking on trigger
            if (isSubMenuOpen) {
              setDeviceDropdownState(initialDropdownState)
              setUserDropdownState(initialDropdownState)
            }
            showAddDeviceMenuGuideTooltip && closeAddDeviceTooltip()
          },
          onGuideTooltipClose: closeAddDeviceTooltip,
          buttonRef,
        }}
      />
      {showRootDropdown && (
        <CustomDropdown
          {...{
            id: ADD_MENU_ID,
            triggerId: ADD_MENU_TRIGGER_ID,
            title: localized("Add item"),
            options, // must be memoized for focus to work correctly
            closeDropdown: handleCloseMainDropdown, // must be memoized for focus to work correctly
            isHidden: isSubMenuOpen,
            openedFromKeyboard: rootDropdownState.openedFromKeyboard,
          }}
        />
      )}
      {deviceDropdownState.show && (
        <CustomDropdown
          {...{
            triggerId: ADD_MENU_TRIGGER_ID,
            title: localized("Add device"),
            options: getDeviceOptions(),
            closeDropdown: closedFromKeyboard => {
              if (closedFromKeyboard) {
                focusBackToSubMenuTrigger(ADD_DEVICE_TRIGGER_ID)
              }

              setDeviceDropdownState(initialDropdownState)
            },
            closeRootDropdown: handleCloseMainDropdown,
            isSubMenu: true,
            openedFromKeyboard: deviceDropdownState.openedFromKeyboard,
            rootDropdownId: ADD_MENU_ID,
          }}
        />
      )}
      {userDropdownState.show && (
        <CustomDropdown
          {...{
            triggerId: ADD_MENU_TRIGGER_ID,
            title: localized("Add user"),
            options: getUserOptions(),
            closeDropdown: closedFromKeyboard => {
              setUserDropdownState(initialDropdownState)

              if (closedFromKeyboard) {
                focusBackToSubMenuTrigger(ADD_USER_TRIGGER_ID)
              }
            },
            closeRootDropdown: handleCloseMainDropdown,
            isSubMenu: true,
            openedFromKeyboard: userDropdownState.openedFromKeyboard,
            rootDropdownId: ADD_MENU_ID,
          }}
        />
      )}
    </Box>
  )
}
export default connect(
  ({
    ticketing: {
      configurations: { enabled: ticketingEnabledFromSettings },
    },
    general: {
      quickMenus: { add: externalRequestToOpenUserDropdown },
      guideTooltips,
    },
  }) => ({
    externalRequestToOpenUserDropdown,
    ticketingEnabledFromSettings,
    showAddDeviceMenuGuideTooltip: guideTooltips.addDeviceMenu,
  }),
)(AddDropdown)
