import { localizationKey } from "js/includes/common/utils"

export const MFA_ERROR_CODES = {
  MFA_REQUIRED: "MFA_REQUIRED",
  INCORRECT_MFA_CODE: "INCORRECT_MFA_CODE",
  bad_u2f_input_format: "bad_u2f_input_format",
}

export const MFA_ERROR_CODE_LIST = [
  MFA_ERROR_CODES.MFA_REQUIRED,
  MFA_ERROR_CODES.INCORRECT_MFA_CODE,
  MFA_ERROR_CODES.bad_u2f_input_format,
]

const MFA_ERROR_MESSAGES = {
  [MFA_ERROR_CODES.INCORRECT_MFA_CODE]: localizationKey("Error during MFA code verification"),
  [MFA_ERROR_CODES.bad_u2f_input_format]: localizationKey("Error during MFA code verification"), // TODO: Add correct message when we have it
}

export const getMfaErrorMessages = errorCode => MFA_ERROR_MESSAGES[errorCode]
