import { Fragment, useCallback, useEffect, useRef, memo } from "react"
import PropTypes from "prop-types"
import { cond, identity, T } from "ramda"
import styled from "@emotion/styled"
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons"
import tokens from "@ninjaone/tokens"
import { Heading, IconButton, StyledHr } from "@ninjaone/components"
import {
  isDownKey,
  isEnterKey,
  isEscapeKey,
  isLeftKey,
  isRightKey,
  isSpaceKey,
  isTabKey,
  isUpKey,
} from "js/includes/common/utils"
import { Flex, visuallyHiddenStyles } from "js/includes/components/Styled"
import OutsideClickAlerter from "../OutsideClickAlerter"
import MenuItem from "./MenuItem"

const FIRST_SELECTABLE_OPTION_INDEX = 0
const CLOSED_FROM_KEYBOARD = true
const OPENED_FROM_KEYBOARD = true

const StyledDropdownContainer = styled.div`
  outline: none;
  position: absolute;
  top: 38px;
  right: 0;
  padding: ${tokens.spacing[1]};
  margin-top: 2px;
  min-width: 320px;
  border-radius: ${tokens.spacing[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  z-index: 5000;
  padding-bottom: ${tokens.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  box-shadow: ${({ theme }) => theme.elevationWeak};
  user-select: none;

  &.visually-hidden {
    ${visuallyHiddenStyles}
  }
`

const CustomDropdown = memo(
  ({
    id,
    triggerId,
    rootTriggerId,
    title,
    options,
    closeDropdown,
    closeRootDropdown,
    isSubMenu,
    isHidden,
    rootDropdownId,
    openedFromKeyboard,
  }) => {
    const dropdownRef = useRef(null)
    const filteredOptions = options.filter(({ show }) => show !== false)

    const handleCloseDropdownOnAction = () => {
      if (isSubMenu) {
        closeRootDropdown()
        return
      }

      closeDropdown()
    }

    const getItemByIndex = index => {
      const menuItems = dropdownRef.current.querySelectorAll("[role='menuitem']")
      return menuItems[index]
    }

    const getCurrentItemIndex = () => {
      const menuItems = dropdownRef.current.querySelectorAll('[role="menuitem"]')
      return Array.from(menuItems).findIndex(item => item === document.activeElement)
    }

    const getNextItemIndex = index => {
      const focusedIndex = getCurrentItemIndex()
      const nextIndex = focusedIndex + 1

      return nextIndex > filteredOptions.length - 1 ? focusedIndex : nextIndex
    }

    const getPreviousItemIndex = index => {
      const focusedIndex = getCurrentItemIndex()
      const previousIndex = focusedIndex - 1

      return previousIndex < 0 ? 0 : previousIndex
    }

    const focusOnItemByIndex = useCallback(index => {
      const menuItem = getItemByIndex(index)
      menuItem.focus()
    }, [])

    const resetMenuItemFocus = event => {
      const menuItem = event.target.closest("[role='menuitem']")

      if (menuItem) {
        menuItem.blur()
      }
    }

    const handleDown = () => {
      const userHasNotFocusedOnAnyItem = document.activeElement === dropdownRef.current

      if (userHasNotFocusedOnAnyItem) {
        focusOnItemByIndex(FIRST_SELECTABLE_OPTION_INDEX)
        return
      }

      const nextIndex = getNextItemIndex()
      focusOnItemByIndex(nextIndex)
    }

    const handleUp = () => {
      const previousIndex = getPreviousItemIndex()
      focusOnItemByIndex(previousIndex)
    }

    const handleRight = () => {
      const selectedOption = filteredOptions[getCurrentItemIndex()]

      if (selectedOption?.hasSubMenu) {
        selectedOption.action(OPENED_FROM_KEYBOARD)
      }
    }

    const handleLeft = () => {
      if (isSubMenu) {
        closeDropdown(CLOSED_FROM_KEYBOARD)
      }
    }

    const handleEnter = () => {
      const selectedOption = filteredOptions[getCurrentItemIndex()]

      if (selectedOption) {
        selectedOption.action(OPENED_FROM_KEYBOARD)
      }

      if (!selectedOption?.hasSubMenu) {
        handleCloseDropdownOnAction()
      }
    }

    const handleKeyPress = event => {
      event.stopPropagation()

      cond([
        [
          isTabKey,
          e => {
            e.preventDefault()
          },
        ],
        [isEscapeKey, () => closeDropdown(CLOSED_FROM_KEYBOARD)],
        [isEnterKey, handleEnter],
        [isDownKey, handleDown],
        [isUpKey, handleUp],
        [isRightKey, handleRight],
        [isLeftKey, handleLeft],
        [isSpaceKey, handleEnter],
        [T, identity],
      ])(event)
    }

    useEffect(() => {
      const dropdown = dropdownRef.current
      if (!dropdown) return

      if (openedFromKeyboard) {
        focusOnItemByIndex(FIRST_SELECTABLE_OPTION_INDEX)
      } else {
        dropdown.focus()
      }
    }, [focusOnItemByIndex, openedFromKeyboard])

    return (
      <OutsideClickAlerter
        {...{
          handleClickOutside: event => {
            const eventComesFromTrigger = !!event.target.closest(`#${triggerId}`)
            const eventComesFromDropdown = !!event.target.closest("[data-ninja-add-menu]")

            if (!eventComesFromTrigger && !eventComesFromDropdown) {
              closeDropdown()
            }
          },
        }}
      >
        <StyledDropdownContainer
          {...{
            role: "menu",
            tabIndex: "-1",
            ref: dropdownRef,
            id,
            "aria-labelledby": "add-menu-title",
            "aria-orientation": "vertical",
            "data-ninja-add-menu": true,
            ...(isHidden && { className: "visually-hidden" }),
            onKeyDown: handleKeyPress,
          }}
        >
          <Flex
            height="21px"
            alignItems="center"
            margin={`${tokens.spacing[3]} ${tokens.spacing[2]} ${tokens.spacing[2]}`}
            gap={tokens.spacing[1]}
          >
            {isSubMenu && (
              <IconButton
                size="sm"
                icon={faArrowLeft}
                handleClick={() => {
                  closeDropdown()
                  document.getElementById(rootDropdownId).focus()
                }}
                aria-hidden="true"
              />
            )}
            <Heading level={2} id="add-menu-title" type="headingXs">
              {title}
            </Heading>
          </Flex>
          {filteredOptions.map(
            (
              {
                action = () => {},
                labelToken,
                LabelComponent,
                TitleComponent,
                ButtonComponent,
                splitAfter,
                ariaLabel,
                ariaDescribedBy,
                hasSubMenu,
                isSubMenuOpen,
                id,
                ...option
              },
              index,
            ) => {
              return (
                <Fragment key={id || labelToken || ariaLabel}>
                  <MenuItem
                    {...{
                      onClick: event => {
                        action()

                        if (!hasSubMenu) {
                          handleCloseDropdownOnAction()
                        }
                      },
                      onMouseMove: () => focusOnItemByIndex(index),
                      onMouseLeave: resetMenuItemFocus,
                      role: "menuitem",
                      tabIndex: "-1",
                      "aria-label": ariaLabel,
                      "aria-describedby": ariaDescribedBy,
                      id,
                      ...(hasSubMenu && { "aria-haspopup": "menu", "aria-expanded": isSubMenuOpen }),
                    }}
                    data-testid="menu-item"
                  >
                    <LabelComponent />
                  </MenuItem>
                  {splitAfter && <StyledHr color="colorBorderWeakest" />}
                </Fragment>
              )
            },
          )}
        </StyledDropdownContainer>
      </OutsideClickAlerter>
    )
  },
)

export default CustomDropdown

CustomDropdown.propTypes = {
  id: PropTypes.string,
  triggerId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  closeRootDropdown: PropTypes.func,
  isSubMenu: PropTypes.bool,
  isHidden: PropTypes.bool,
  rootDropdownId: PropTypes.string,
  openedFromKeyboard: PropTypes.bool,
}
